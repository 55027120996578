
@import "src/stylesheets/abstracts/variables.scss";


$text-padding: .8rem;
$color-red: #FD2C34FF;
.custom-input {
    position: relative;
    margin: 2rem 0 1rem;
    .input,
    .input-lable{
        font-size: 1.5rem;
    }
    .input {
        padding: $text-padding;
        width: calc(100% - ($text-padding * 2));
        border: none;
        border-radius: 5px;
        font: inherit;
        color: #777777FF;
        background-color: transparent;
        outline: 1px solid #D5D5D5FF;
    }
    .input-label {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        transform: translate($text-padding, $text-padding);
        transition: transform .25s;
    }

    .input:disabled{
        opacity: .5;
    }

    .input:disabled + .input-label{
        opacity: .7;
    }
    // Alternate Code where inputs are no longer red when unselected
    .input:focus+.input-label,
    .input:not(:placeholder-shown)+.input-label {
        transform: translate($text-padding, -$text-padding) scale(.9); //Posistion of the label when selected
        padding-inline: 5px;
        background-color: #fff; //This needs to be the color of the document
    }
    .input:focus+.input-label,
    .input:not(:placeholder-shown):invalid+.input-label{
        color: $color-red;
    }
    .input:focus,
    .input:not(:placeholder-shown):invalid {
        outline-color: $color-red;
    }
}
