
@import "src/stylesheets/abstracts/variables.scss";


.referral-email{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;
    overflow-y: scroll;

    .title{
        font-size: 3rem;
        height: 20%;
    }
    .description{
        height: 20%;
    }
    .input-container{
        margin: auto;
        height: 45%;
        width: 80%
    }
    .btn-container{
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}

@media only screen and (max-height: 975px) {
    .referral-email {
        .title {
            font-size: 2rem;
        }
    }
}
