.card-base{
    @extend .soft-shadow;
    border-radius: $border-radius-card;
    background-color:     $color-white;
    max-height: calc(100%);
    overflow-x: hidden;
    box-sizing: border-box;
    @media only screen and (min-height: 800px){
        max-height: calc(100% - $app-bar-height)
    }
    @media only screen and (min-height: 1200px){
        max-height: calc(90% - $app-bar-height)
    }
    @media only screen and (max-width: $mobile-width){
        max-height: unset;
    }
}

::-webkit-scrollbar {
    width: 0.5em;
    background: none;
    position: relative;
    &-track{
        background: rgb(156, 156, 156, 0.25);
        border-radius: .25em;
    }
    &-thumb {
        background: rgb(156, 156, 156, 0.75);
        width: 1em;
        border-radius: .25em;
    }
}

.wide-card{
    @extend .card-base;
    border-radius: unset;
    max-height:    100vh;
    height:        calc(100vh - $app-bar-height);
    @media screen and (min-height: 990px) and (min-width: 600px) {
        border-radius: $border-radius-card;
        top:          3.125rem; // 50px
        width:      32.8125rem; // 528
        min-height:   29.25rem; // 468px
        max-height:      50rem; // 800px
        overflow-x:    visible;
    }
}