// Default styles so that *hopefully* SVGs aren't initially invisible
svg.icon{
    min-height: 10px;
    min-width:  10px;
    height:     1rem;
    width:      1rem;
    path.filled{
        fill:  $color-opt-black;
        color: $color-opt-black;
    }
}

svg.icon.opt-icon{
    height:     4.6rem;
    width:  calc(1.78 * 4.6rem);
    min-height: 2rem;
    min-width: calc(1.78 * 2rem);
    path.icon-path{
        fill: $color-primary;
    }
}

@media only screen and (max-height:$no-nav-height) {
    svg.icon.opt-icon {
        min-height: unset;
        min-width: unset;
        height: 3rem;
    }
    // svg.icon.opt-icon.white-opt {
    //     path.icon-path {
    //         fill: $color-white;
    //     }
    // }
}
@media only screen and (max-width: $mobile-width) {
    svg.icon.opt-icon {
        min-height: unset;
        min-width: unset;
        height: 3rem;
    }
}

svg.icon.profile{
    path.filled{
        fill: $color-opt-black;
    }
}
