// OPTme's default variables to be used accross different
// OPT app versions/levels

/**
 *  COLORS
 */
$color-red-1:              #Fd2c34;
// $color-red-1:              #DA3A34FF; //old
$color-red-2:              #E23D35FF;
$color-red-3:              rgb(224, 82, 75);
$color-green-1:            #4BC946FF;
$color-green-2:            #51BD99FF;
$color-blue-1:             #63ACE3FF;
$color-blue-2:             #57C3FAFF;
$color-purple:             #8951F0FF;

$color-white:              #FFFFFFFF;
$color-grey-E2:            #E2E4E9FF;
$color-grey-D3:            #D3D3D3FF;
$color-grey-D5:            #D5D5D5FF;
$color-grey-BF:            #BFBFBFFF;
$color-grey-A1:            #A1A1A1FF;
$color-grey-80:            #808080FF;
$color-grey-77:            #777777FF;
$color-grey-6F:            #6F6F6FFF;
$color-grey-3A:            #3A3A3AFF;
$color-grey-37:            #373737FF;
$color-grey-32:            #323232FF;
$color-black:              #000000FF;

$color-opt-black:          #6C6C6CFF;
$color-opt-grey:           #CFCFCFFF;

$color-opt-red:           $color-red-1;
$color-opt-blue:         $color-blue-1;

$color-primary:           $color-red-1;
$color-secondary:        $color-blue-1;
$color-tertiary:        $color-green-1;
$color-quaternary:       $color-purple;

$color-disabled:        $color-grey-D3;

$color-grey-1:          $color-grey-E2;
$color-grey-2:          $color-grey-D3;
$color-grey-3:          $color-grey-A1;
$color-grey-4:          $color-grey-6F;
$color-grey-5:          $color-grey-3A;

$color-success:         $color-green-1;
$color-error:             $color-red-1;
$color-link:             $color-blue-2;

$color-background-solid: $color-grey-1;
$color-background:       $color-grey-1;

$color-text-placeholder: $color-grey-4;

$color-card-bkg:          $color-white;
$color-card-shadow:        #0000000D;

$border-radius-card:            2.5rem; // 40px
$border-radius-input:          0.25rem;

$app-bar-height:               6.25rem; // 100px
$view-height: calc(100vh - 6.25rem);
$card-height: calc(100%); //If you change the card-height make sure the card-header-height matches
$card-header-height: 70px; // This matches the $card-height


$no-nav-height: 1100px; //This is for the height when the Navbar moves from the top to the card
$mobile-width: 500px; //This is for when the mobile media query takes over

$shadow-card:   unset;
$shadow-button: unset;

