
@import "src/stylesheets/abstracts/variables.scss";


.no-scroll{
    overflow: hidden;
}

.retry-payment-container{
    width: 100%;
    height: calc(100% - 50px);
} 
.retry-payment-container > .page {
    width: 100%;
    height: 100%;
    position: relative;
}

.retry-payment-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity .25s ease;
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }

    .bring-up-enter-active, .bring-up-leave-active {
        transition: transform .25s ease, opacity .25s ease;
    }
    .bring-up-enter-from {
        transform: translateY(100%);
        opacity: 0;
    }
    .bring-up-leave-to {
        transform: translateY(100%);
        opacity: 0;
    }
}
