
@import "src/stylesheets/abstracts/variables.scss";



nav.navbar {
    width: 100%;
    height: 6.25rem;
    background-color: $color-white;
    position: relative;
    display: flex;
    justify-content: center;

    .navbar-container {
        width: 32.8125rem;
        height: 100%;
        display: flex;
        flex-direction: row;

        button.opt-logo,
        button.profile {
            width: 50%;
            height: 100%;
            min-width: 6.25rem;
        }

        .opt-logo {
            height: 100%;
            width: calc(6.25rem * 1.78);
            display: flex;
            flex-direction: row;

            .hide-profile {
                visibility: hidden;
            }
            .profile {
                width: 6.25rem;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;

                &.proxy-user{
                    .profile-pic,.profile-initials,.icon{
                        border: 2px solid purple;
                    }
                }

                .profile-pic {
                    height: 3.6rem;
                    width: 3.6rem;
                    border-radius: 100%;
                    object-fit: cover;
                }

                .profile-initials {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    height: 3.6rem;
                    width: 3.6rem;
                    font-size: 1.4rem;
                    border-radius: 100%;
                    background-color: $color-grey-D5;
                    color: white;
                }

                .icon {
                    height: 3.6rem;
                    width: 3.6rem;
                }
            }
        }


        .hamburger-menu {
            width: 50%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            box-sizing: border-box;
            padding-right: 3rem;
        }

        // This is the animation section for the menu
        .slide-in-enter-active, .slide-in-leave-active{
            transition: all .3s ease;
        }
        .slide-in-enter-from, .slide-in-leave-active{
            opacity: 0;
            transform: translate(100%)
        }
    }
    .menu{
        position: fixed;
        top: 118px;
        right: 50%;
        left: 50%;
        transform: translate(30.5%);
        width: 200px;
        font-size: 1rem;
        z-index: 5;
        overflow: none;

        .menu-options {
            display:flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            height: 100%;
            padding: 1rem 0;
            background-color: white;
            border: 2px solid $color-grey-D3;

            .menu-item {
                font-size: 20px;
                font-weight: thin;
                padding: 0.5rem 0;
                color: $color-grey-77;
                text-decoration: none;

                &:hover {
                    cursor: pointer;
                    color: $color-white;
                    background-color: $color-red-1;
                    font-weight: bold;
                }

                &.router-link-active{
                    color: $color-white;
                    background-color: $color-primary;
                    font-weight: bolder;
                }
            }
        }
    }
}

@media only screen and (max-height: $no-nav-height) {
    nav.navbar {
        height: 70px;
        .opt-logo{
            .profile{
                padding-left: unset;
                
                .profile-pic{
                    height: 2.9rem;
                    width:  2.9rem;
                }
                .profile-initials{
                    height: 2.9rem;
                    width:  2.9rem;
                    font-size: 1.2rem;
                }
                .icon{
                    height: 2.9rem;
                    width:  2.9rem;
                    font-size: 1.2rem;
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    nav.navbar {
        .navbar-container {
            flex-direction: row-reverse;
        }
        .opt-logo {
            position: absolute;
            left: 18%;

            .profile {
                padding-left: 0;

                .profile-pic,
                .profile-initials,
                .icon {
                    height: 2.9rem;
                    width: 2.9rem;
                    font-size: 1.2rem;
                }
            }
        }

        .hamburger-menu {
            padding: unset;
        }

        .menu {
            top: 80px;
            transform: unset;
            left: unset;
            right: 0;
        }
        -webkit-box-shadow: 0px 0px 04px 1px #00000024;
        box-shadow: 0px 0px 04px 1px #00000024;
    }
}
