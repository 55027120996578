
@import "src/stylesheets/abstracts/variables.scss";


.profile-settings {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    .profile-header {
        width: 100%;
        height: 10%;
        background-color: $color-primary;
        box-sizing: border-box;

        .header-title,
        .link-count {
            font-size: 1.5rem;
            color: $color-white;
        }
    }

    .profile-details{
        box-sizing: border-box;
        padding: 2rem 0;
        height: 32%;

        .profile-container{
            position: relative;

            &.proxy-user{
                .profile-pic,
                .profile-initials,
                .icon{
                    border: 2px solid purple;
                }
            }

            .profile-pic-overlay{
                cursor: pointer;
                position: absolute;
                width: 100%;
                height: 100%;
                border-radius: 100%;
                justify-content: center;
                align-content: center;
                align-items: center;
                text-align: center;
                display: none;

                .text{
                    z-index: 3;
                    font-weight: bold;
                    color: $color-opt-black;

                    svg{
                        height: 15px;
                        width: 15px;
                        fill: $color-opt-black;
                    }
                }
                .highlight{
                    z-index: 2;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    opacity: 0.4;
                    background-color: $color-grey-1;
                }
            }
            .profile-icon-container:hover{
                .profile-pic-overlay{
                    display: flex;
                }
            }

            .profile-pic,
            .profile-initials,
            .icon{
                height: 7.2rem;
                width:  7.2rem;
                border-radius: 100%;
                object-fit: cover;
            }
            .profile-initials{
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                font-size: 1.4rem;
                background-color: $color-grey-D5;
                color: white;
            }

            .profile-qr-container{
                position: absolute;
                right: -0.2rem;
                bottom: -0.2rem;
                height: 2.4rem;
                width:  2.4rem;
                border-radius: 100%;
                border: 1px solid white;
                background-color: white;
                // z-index: 3;
                display: flex;
                justify-content: center;
                align-content: center;
                align-items: center;
                text-align: center;
                cursor: pointer;

                .profile-qr-overlay{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    justify-content: center;
                    align-content: center;
                    align-items: center;
                    text-align: center;
                    display: none;

                    .text{
                        z-index: 4;
                        font-size: 0.8rem;
                        font-weight: bold;
                        color: $color-opt-black;
                    }

                    .highlight{
                        z-index: 3;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        border-radius: 100%;
                        opacity: 0.5;
                        background-color: $color-grey-1;
                    }
                }
                &:hover{
                    .profile-qr-overlay{
                        display: flex;
                    }
                }
                .profile-qr{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    background-color: $color-opt-red;
                    border-radius: 100%;
                    border: 3px solid white;
                    color: white;
                }
                .qr-background{
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .profile-name-container{
            font-size: 22px;
            margin: 0.5rem;
            text-align:center;
            .profile-email {
                font-size:16px;
            }
        }
    }
    .profile-actions{
        width: 70%;
        height: 45%;
        margin-inline: auto;
        display: flex;
        flex-direction: column;
        gap: clamp(1rem, 2vh, 2rem);
        max-height: 35vh;
    }

    .bottom-settings {
        width: 100%;
        height: 13%;
        margin-inline: auto;
        margin-top: auto;
        min-height: 90px;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;
        .return {
            padding: 0;
        }
    }

    button.outset-icon{
        cursor: pointer;
        display: flex;
        align-items: center;
        gap: 1.5rem;
        min-height: 4rem;
        background-color: transparent;
        justify-content: center;
        border-radius: 3em;
        outline: 2px solid transparent;

        .icon{
            height: 2rem;
            width: 2rem;
            outline: 1px solid $color-primary;
            outline-offset: 0.5rem;
            border-radius: 100%;

            svg{
                fill: $color-grey-4 !important;
                width: 32px;
                height: 32px;
            }
            svg path {
                fill: $color-grey-4 !important;
            }
        }

        .text{
            text-align: left;
            font-size: 1.5rem;
            color: $color-opt-black;
        }
        &:focus{
            .text{
                color: $color-primary;
            }
        }
    }
    .overlay{
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;
        left: 0;
        // z-index: 5;
        background-color: white;
    }

    .overlay {
        &.v-enter-active, &.v-leave-active {
            transition: transform .25s ease, opacity .25s ease;
        }
        &.v-enter-from {
            transform: translateY(100%);
            opacity: 0;
        }
        &.v-leave-to {
            transform: translateY(100%);
            opacity: 0;
        }
    }

    .version {
        position: absolute;
        bottom: 0;
        padding: 1em;
        width:100%;
        text-align:center;
    }
}
.no-scroll{
    overflow: hidden;
}

@media only screen and (max-width: $mobile-width){
    .profile-settings {
        height: 100%;
        overflow: hidden;
        .profile-header {
            min-height: unset;
            // height: 10%;
        }
        .profile-details {
            padding: unset;
            // height: 27%;
        }
        .profile-actions {
            height: unset;
            justify-content: center;
        }
        .overlay{
            position: fixed;
        }
        .bottom-settings {
            // margin-top: unset;
            padding-bottom: 1rem;
        }
        .version {
            padding: unset;
            padding-bottom: .2rem;
        }
    }
}


