
@import "src/stylesheets/abstracts/variables.scss";


.no-scroll {
    overflow: hidden;
}

.active-links {
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;

    .links-tabs {
        height: 85%;
        width: 100%;
        box-sizing: border-box;

        .tab {
            position: relative;
            height: calc(90% - 3rem);
            padding: 2rem 0;
            overflow-y: auto;

            .standard-link-container{
                padding-top:5%;
                height:25%;
                display:flex;
                flex-direction: column;
                justify-content: flex-start;
                .setupStandard{
                    color: $color-grey-77;
                    font-weight: 400;
                    font-size: 24px;
                    &:hover{
                        cursor: pointer;
                    }
                }
                .edit-standard{
                    color: $color-primary;
                    text-align: center;
                    font-weight: 400;
                    &:hover{
                        cursor: pointer;
                    }
                }
             
            }

            &.referral-tab {
                height: calc(90% - 6rem);
            }

            &::-webkit-scrollbar {
                width: 0.5em;
                background: none;
                position: relative;

                &-track {
                    background: rgb(156, 156, 156, 0.25);
                    border-radius: .25em;
                }

                &-thumb {
                    background: rgb(156, 156, 156, 0.75);
                    width: 1em;
                    border-radius: .25em;
                }
            }
        }
    }

    .referral {
        width: 100%;
        height: 5%;
        box-sizing: border-box;
    }

    .what-now{
        z-index: 0;
        font-size: 1.3rem;
        color: $color-link;
        cursor: pointer;
    }

    .btn-links {
        // width: 100%;
        // display: flex;
        // flex-direction: row;
        // justify-content: space-around;
        // align-items: center;
        // box-sizing: border-box;
        // position: sticky;
        // // bottom: 5%;

        // .return {
        //     padding: 0;
        // }

        width: 90%;
        height: 10%;
        margin: auto auto 0 auto;
        display: flex;
        box-sizing: border-box;
        justify-content: space-between;

        button.primary{
            width: 12rem;
            svg{
                path{
                    fill: white;
                }
            }
        }
    }

    .loader-container {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        background-color: rgba(240, 240, 240, 0.5);
    }

    .preview-overlay {
        position: absolute;
        z-index: 5;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $color-white;

        
    }

    .v-enter-active,
    .v-leave-active {
        transition: transform .25s ease;
    }

    .v-enter-from {
        transform: translateY(100%);
    }

    .v-leave-to {
        transform: translateY(100%);
    }
}

@media only screen and (max-width: $mobile-width) {
    .active-links {
        .links-tabs {
            height: 80%;
            .tab {
                overflow-y: none;

                .standard-link-container {
                    overflow-y: auto;
                }
            }
        }
        .btn-links {
            position: unset;
            padding-bottom: 5rem;
        }
    }
}

