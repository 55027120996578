
@import "src/stylesheets/abstracts/variables.scss";


.loader-container{
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 5;
    background-color: rgba(128, 128, 128, 0.103);
    border-radius: 2rem;
}

.no-scroll{
    overflow: hidden;
}

.error-container{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
}

.meeting-request-container, .meeting-request-container > .page {
    width: 100%;
    overflow-y: hidden;
}

.meeting-request-container{
    height:100%;
    box-sizing: border-box;
    padding: 0 2rem;
}

.meeting-request-header{
    // margin-bottom: 1em;
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}

.meeting-request-container > .page {
    height: 85%;
}

.meeting-request-container > .page .slide {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;

    .slide-header,
    .slide-container,
    .button-container{
        width: 100%;
    }

    .slide-header{
        height: 8%;
        font-size: 2rem;
        text-align: center;
    }

    .slide-content{
        height: 85%;
        padding: 0 2rem;
        overflow: auto;
    }

    .button-container {
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6%;
        }
    }
}


.meeting-request-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity 0.25s ease;
        position: relative;
        .slide{
            position: absolute;
        }
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}
@media only screen and (max-width: $mobile-width) {
    .meeting-request-container > .page .slide {
        height: 100%;
        overflow-y: auto;
    }
    .meeting-request-container {
        height: 100%;
        // height: calc(100% - 30px);
        padding: 0 0;
        .meeting-request-header{
            margin:unset;
        }
    }
    .meeting-request-container > .page .slide {
        .slide-content{
            padding: 0 0;
        }

        .button-container{
            height: 20%;
        }
    }
}

