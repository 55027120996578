
@import "src/stylesheets/abstracts/variables.scss";


article.admin-proxy-page {
    padding: 1rem;
    height: 100%;
    overflow: hidden;
    box-sizing: border-box;

    display: grid;

    .proxy-user-list {
        margin: 0 4rem;
        max-height: 100%;
        overflow-y: auto;

        .proxy-user {
            &:hover {
                background: grey;
                cursor: pointer;
            }
        }
    }

    h2 {
        text-align: center;
    }
}
