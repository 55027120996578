
@import "src/stylesheets/abstracts/variables.scss";


.meeting-rate-container{
    .content-container{
        .rate-field {
            width: 100%;
            height: 45%;
            min-height: 500px;
            box-sizing: border-box;

            &.payer-rate{
                min-height: 400px;
            }
        }

        .check-container{
            width: 100%;
            // margin-top: 10%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            box-sizing: border-box;
            // margin-bottom: 5rem;

            .horizontal-container{
                display: flex;
                align-items: center;
                justify-content: center;
                color: $color-primary;
                font-size: 1.25rem;
                width: 100%;
            }
            .checkbox{
                margin-right: 2%;
            }
            .terms-container{
                display: flex;
                flex-direction: column;
                font-size: 1rem;
                .highlighted-terms{
                    color: $color-primary;
                }
                color: $color-opt-black;
            }
        }
    }
    .what-for{
        margin-top: 1rem;
        text-align: center;
        font-size: 1.2rem;
        color: $color-link;
        cursor: pointer;
    }
}

.meeting-rate-container{
    @media only screen and (max-height: 1000px){
        .rate-field.flex-center{
            min-height: fit-content;
            height: clamp(380px, 44vh, 500px);
            max-height: 100%;
        }
    }
}
.meeting-rate-container{
    @media only screen and (max-height: 800px){
        .rate-field.flex-center {
            min-height: unset;
            font-size: 1.75rem;
            height: clamp(380px, 44vh, 500px);
            .input-pay-container{
                height: unset;
            }
        }
        .earnings-container{
            .earnings-total{
                font-size: 1.25rem;
            }
            .earnings-info div{
                font-size: 1rem;
            }
        }
    }
    @media only screen and (max-height: 710px){
        .rate-container{
            .rate-title{
                font-size: 1.5rem;
                height: fit-content;
            }
            input{
                font-size: 3rem;
            }
        }
        .rate-field.flex-center,
        .rate-container.flex-center{
            height: clamp(316px, 44vh, 500px);
            gap: 1rem;
        }
     }
    @media only screen and (max-width: 500px){
        .check-container {
            margin-bottom: unset;
        }
    }
}
