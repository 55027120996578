
@import "src/stylesheets/abstracts/variables.scss";


.meeting-rescheduled-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;

    .slide-header,
    .slide-container,
    .button-container{
        width: 100% !important;
    }

    .slide-header{
        height: 10%;
        font-size: 3rem;
        text-align: center;
    }

    .slide-content{
        height: 80% !important;
        overflow-y: auto;
        padding: 0 2rem;
    }

    .button-container {
        height: 10% !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6%;
        }
        .primary{
            background-color: $color-primary;
        }
    }
    
    
    .slide-content{

        .rescheduled-info-container{
            min-height: 35%;

            font-size: 1.5rem;
            text-align: center;

            display:flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            color: white;
            background: $color-primary;
            border-radius: 5px;

            .hold-info{
                width:80%;
                line-height: 1;
                .more-info{
                    font-size: .9rem;
                    font-weight: 300;
                }
            }

            .date-and-time{
                font-weight: bold;
            }

            .meeting-with{
                display: flex;
                flex-direction: column;
            
                &-name{
                    font-weight: bold;
                }
            }
            .extra-info{
                width: 90%;
                font-size: 1rem;
            }

        }

        .other-details-container{
            padding: 2rem 0;
            text-align: center;
            font-size: 1.5rem;
            color: $color-opt-black;
        }
    }
}

