
@import "src/stylesheets/abstracts/variables.scss";



.twoPointSlider{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background: white;
    .slider-container{
        position: relative;
        width: 100%;
        height: 90px;
        .start,
        .end{
            display: unset;
            position: absolute;
            height: 1.3rem;
            width: 1.3rem;
            background-color: #dadae5;
            pointer-events: auto;
            border-radius: 50%;
            top: 36%;
        }
        .start{
            left: 0;
        }
        .end{
            right: 0;
        }
        .slider{
            z-index: 1;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 6px;
            background-color: transparent;
            pointer-events: none;
        }
        .slider-track{
            width: 100%;
            height: 3px;
            position: absolute;
            margin: auto;
            top:50%;
            bottom:50%;
            border-radius: 5px;
            @supports (-moz-appearance:none) {
                margin-top: -4px;
            }
        }
        .slider::-webkit-slider-runnable-track{
            -webkit-appearance: none;
            height: 5px;
        }
        .slider::-moz-range-track{
            -moz-appearance: none;
            height: 5px;
        }
        .slider::-ms-track{
            appearance: none;
            height: 5px;
        }
        .slider::-webkit-slider-thumb{
            -webkit-appearance: none;
            height: 1.3rem;
            width: 1.3rem;
            background-color:  $color-primary;
            cursor: pointer;
            margin-top: -6px;
            pointer-events: auto;
            border-radius: 50%;
        }
        .slider::-moz-range-thumb{
            -webkit-appearance: none;
            appearance: none;
            height: 1.3rem;
            width: 1.3rem;
            cursor: pointer;
            border-radius: 50%;
            background-color:  $color-primary;
            pointer-events: auto;
            border-color: transparent;
        }
        .slider::-ms-thumb{
            appearance: none;
            height: 1.3rem;
            width: 1.3rem;
            cursor: pointer;
            border-radius: 50%;
            background-color:  $color-primary;
            pointer-events: auto;
        }
        .slider:active::-webkit-slider-thumb{
            background-color: #ffffff;
            border: 3px solid  $color-primary;
        }
    }
    .values{
        display: flex;
        width: 100%;
        // justify-content: space-evenly;
        font-size: 1.6rem;
        position: absolute;
        margin: auto;
        top: 0;
        color: $color-primary;
        .range1, .range2{
            position: absolute;
        }
        .range1{
            left: 18%;
        }
        .range2{
            right: 18%;
        }
        &::before{
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            margin: auto;
        }
    }
}
