
@import "src/stylesheets/abstracts/variables.scss";


.feedback{
    width: 100%;
    height: 100%;
    padding: 5% 10% 0;
    box-sizing: border-box;
    text-align: center;

    .title{
        height: 10%;
        min-height: 70px;
        color: $color-opt-black;
        font-size: 2.5rem;
        box-sizing: border-box;
    }

    .message1 {
        height: 5%;
        min-height: 50px;
        box-sizing: border-box;
        font-size: 1.5rem;

        .name{
            font-weight: bold;
        }
    }

    .message2 {
        height: 20%;
        min-height: 100px;
        box-sizing: border-box;
        font-size: 1.5rem;
        
        .name{
            font-weight: bold;
        }
    }

    .rating-container{
        height: 15%;
        min-height: 150px;

        .note-feedback{
            font-size: 1.3rem;
        }
    }

    .feedback-container-meeting{
        height: 35%;
        width: 100%;
        min-height: 200px;
        .checkbox-container{
            height: 15%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
        }

        .note{
            margin: 2rem 0;
            width: 97%;

            input{
                height: 100%;
            }
        }
    }

    .button-container-feedback{
        height: 15%;
        .feedback{
            height: unset;
        }
        .cancel{
            font-size: 1rem;
        }
    }
}

.range-slider{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;
    background: white;

    .slider-container{
        position: relative;
        width: 100%;
        height: 90px;

        .start,
        .end{
            display: unset; 
            position: absolute;
            height: 1.7rem;
            width: 1.7rem;
            background-color: #dadae5;
            pointer-events: auto;
            border-radius: 50%;
            top: 34%;
        }
        .start{
            left: 0;
        }
        .end{
            right: 0;
        }

        .slider{
            z-index: 1;
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            width: 100%;
            outline: none;
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 6px;
            background-color: transparent;
            pointer-events: none;
        }
        .slider-track{
            width: 100%;
            height: 8px;
            position: absolute;
            margin: auto;
            top:0;
            bottom:0;
            border-radius: 5px;
            background: #dadae5;
        }
        .slider::-webkit-slider-runnable-track{
            -webkit-appearance: none;
            height: 5px;
        }
        .slider::-moz-range-track{
            -moz-appearance: none;
            height: 5px;
        }
        .slider::-ms-track{
            appearance: none;
            height: 5px;
        }
        .slider::-webkit-slider-thumb{
            -webkit-appearance: none;
            height: 1.7rem;
            width: 1.7rem;
            background-color: $color-opt-red;
            cursor: pointer;
            margin-top: -9px;
            pointer-events: auto;
            border-radius: 50%;
        }
        .slider::-moz-range-thumb{
            -webkit-appearance: none;
            height: 1.7rem;
            width: 1.7rem;
            cursor: pointer;
            border-radius: 50%;
            background-color: $color-opt-red;
            pointer-events: auto;
        }
        .slider::-ms-thumb{
            appearance: none;
            height: 1.7rem;
            width: 1.7rem;
            cursor: pointer;
            border-radius: 50%;
            background-color: $color-opt-red;
            pointer-events: auto;

        }
        .slider:active::-webkit-slider-thumb{
            background-color: #ffffff;
            border: 3px solid $color-opt-red;
        }
    }

    .values{
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-size: 1.6rem;
        position: absolute;
        margin: auto;
        top: 0;
        color:$color-opt-red;

        span{
            width: 1.7rem;
        }

        .range1,
        .range2{
            color: #dadae5;
        }

        .value{
            position: absolute;
        }

        &::before{
            content: "";
            position: absolute;
            height: 0;
            width: 0;
            margin: auto;
        }
    }
}

@media only screen and (max-height: $no-nav-height){
    .feedback {
        .title,
        .message1,
        .message2 {
            min-height: unset;
        }
        .message2 {
            height: 10%;
            font-size: 1.2rem;
        }
        .feedback-container-meeting {
            height: 35%;
        }
        .button-container-feedback {
            height: 15%;

        }
    }
}

@media only screen and (max-width: $mobile-width){
    .feedback {
        .message2 {
            height: 10%;
            font-size: 1.2rem;
        }
        .feedback-container-meeting {
            height: 45%;
        }
        .button-container-feedback {
            height: 10%;
        }
    }
}
