
@import "src/stylesheets/abstracts/variables.scss";


.offer-page {
    width: 100%;
    height: 100%;

    .preview-header {
        box-sizing: border-box;
        width: 100%;
        height: 10%;
        min-height: 70px;
        justify-content: space-evenly;
        text-align: center;

        .title {
            width:100%;
            font-size: 3rem;
            font-weight: 300;
            color: $color-opt-black;
        }
        .close {
            button {
                width: 70%;
            }
        }
        .spacer,
        .title,
        .close {
            width: 33%;
            button{
                padding-bottom: 0;
            }
        }
    }
    .offer-container {
        width: 100%;
        height: 80%;
        min-height: 580px;
        box-sizing: border-box;
        padding: 1rem 3rem;

        .header {
            text-align: center;
            height: 20%;
            min-height: 140px;
            width:100%;

            .description {
                font-size: 1.5rem;
                height: 20%;
            }
            .title {
                font-size: 4rem;
                height: 80%;
                width: 100%;
                justify-content: center;

                .logo {
                    color: $color-red-1;
                    padding-top:3%;
                    margin-right: 20px;
                }
                svg.icon.opt-icon {
                    height:auto;
                }
                .invite-text{
                    font-size: 4.75rem;
                    color: $color-grey-3;
                    white-space: nowrap;
                    overflow: hidden;
                }
            }
        }
        .offer-card {
            width: 420px;
            height: 250px;
            box-sizing: border-box;
            padding: 2rem;
            margin-bottom: 1rem;
            box-sizing: border-box;
            color: $color-white;
            background-color: $color-primary;
            border-radius: 2rem;
            flex-direction: column;

            .from {
                font-size: 22px;

                .sender {
                    font-weight: bold;
                }
            }
            .offer-amount {
                font-size: 4rem;
                font-weight: 500;

                .currency {
                    vertical-align: top;
                    font-size: 22;
                    font-weight: bold;
                }
                .amount {
                    line-height: 7rem;
                    font-size: 115px;
                    font-weight: bold;
                }
            }
            .description {
                font-size: 22px;
                color: white;
                .duration {
                    font-weight: bold;
                }
            }
        }
        .note {
            // margin-top:2rem;
            width: 90%;
            max-height: 40%;
            position: relative;
            
            .description-note {
        
                height:8rem;
                overflow-y: auto;
                overflow-x: none;
                padding-left:3rem;
                font-size: 16px;
                font-weight: normal;
                color: $color-grey-77;
            }
            .smallNote{
                display:flex;
                align-items: center;
            }
            .icon-header{
                position: absolute;
                left:-10%;
                top:20%;
                display:flex;
                justify-content: center;
                align-items: center;
     
                .user-icon{
                    display:flex;
                    justify-content: center;

                    .profile{
                        background: $color-white;

                        &,.profile-pic,.profile-initials,svg{
                            height: 4.75rem;
                            width: 4.75rem;
                            border-radius: 50%;
                            color: $color-white;
                        }
                        .profile-pic{
                            object-fit: cover;
                        }
                        .profile-initials{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            font-size: 2.25rem;
                            background-color: rgba($color-primary, 0.67);
                        }
                    }
                }
            }
            // .profile{
            //     position: absolute;
            //     left:-15%;
            //     top:0;
            //     width: 6.2rem;
            //     height:100%;
            //     display:flex;
            //     justify-content: center;
            //     align-items:center;

            //     .profile-pic{
            //         height: 5.6rem;
            //         width: 5.6rem;
            //         border-radius: 100%;
            //         object-fit: cover;
            //     }
            //     .profile-initials{
            //         display:flex;
            //         justify-content: center;
            //         align-items: center;
            //         height: 5.6rem;
            //         width: 5.6rem;
            //         font-size: 1.6rem;
            //         border-radius: 100%;
            //         background-color: $color-grey-D5;
            //         color: white;
            //     }
            // }
        }
        .offer-spacer{
            height: 36%;
        }
    }
    .button-offer {
        height: 10%;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .error-body{
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .error-header{
            font-size: 2rem;
            min-height: 70px;
        }

        .error-description{
            font-size: 1.5rem;
            min-height: 70px;
            color: $color-red-3
        }

        .error-link{
            font-size: 1.5rem;
            text-decoration: none;
            padding: 0.5rem 1rem;
            background: $color-red-1;
            color: white;
            border-radius: 2rem;
        }
    }
}

@media only screen and (min-width: $mobile-width) and (max-height: 750px){
    .offer-page{
        .offer-container{
            min-height: 500px;
            .offer-card{
                height: 200px;
                .offer-amount{
                    font-size: 2rem;
                    .currency{
                        line-height: 45px;
                        font-size: 30px;
                    }
                    .amount{
                        line-height: 70px;
                        font-size: 70px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .offer-page {
        .preview-header {

        }
        .offer-container {
            padding-top: 2rem;
            box-sizing: border-box;
            min-height: unset;
            height: 75%;
            .header {
                .title {
                    .invite-text {
                        font-size: 3rem;
                    }
                }
            }
            .offer-card {
                width: 320px;
                height: 170px;
                padding: 1rem;
                // margin-bottom: 20%;

                .from {
                    font-size: 1.1rem;
                }
                .offer-amount {
                    font-size: 2.5rem;

                    .amount {
                        font-size: 3rem;
                        line-height: 5rem;
                    }
                }
            }
            .note {
                padding: .5rem;
                .icon-header{
                    .user-icon{
                        &,.profile-pic,.profile-initials,svg{
                            height: 3.5rem;
                            width: 3.5rem;
                        }
                    }
                }
                .description-note {
                    width: 95%;
                    white-space: wrap;
                    text-overflow: ellipsis;
                    overflow: auto;
                }
            }
        }
        .button-offer {
            // margin-top: 20%;
        }
    }
}

