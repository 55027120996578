
@import "src/stylesheets/abstracts/variables.scss";


.upcoming-meeting{
    display: flex;
    // justify-content: center;
    margin-bottom: 1rem;
    margin-top: 1rem;

    .meeting-date{
        // padding-top: 1.5rem;
        // color: $color-opt-black;
        height: 80px;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width:16%;
        background: #f2f2f2;
        // font-size: 1.25em;
        .month{
            padding-right:15%;
            font-size: 18px;
            margin:none;
        }
        .day{
            line-height:25px;
            padding-right:15%;
            font-size: 32px;
            font-weight: bold;
        }
        &.previous{
            color: $color-opt-black;
        }
    }
    .meeting-body{
        width: 80%;
        // background-color: $color-grey-1;
        // padding: 1rem;
        // border: 1px solid $color-grey-1;

        &.failingPayment{
            border-color: $color-red-1;
        }
        &:hover{
            cursor: pointer;
        }

        .meeting-description{
            justify-content: center;
            align-content: center;
            .meeting-icon{
                width: 3em;
            }
            .meeting-details{
                display:flex;
                flex-direction: column;;
                width:80%;
                height: 80px;
                // flex-basis: 55%;
                .recipient-string{
                    padding-top: 5%;
                    padding-left:5%;
                    font-size: 18px;
                }
                .meeting-info{
                    padding-left:5%;
                }
                // .recipient-name{
                //     font-weight: medium;
                //     min-height: 2rem;
                //     font-size: 1.3em;
                //     white-space: nowrap;
                //     overflow: hidden;
                //     text-overflow: ellipsis;
                // }
                .meeting-schedule{
                    display: flex;
                    gap: 1rem;
                    .duration{
                        flex-basis: 4rem;
                        font-size: 1.1em;
                    }
                    .start-time{
                        font-size: 1.1em;
                    }
                }
            }
            .meeting-payment{
                flex-basis: 20%;
                font-size: 1.3em;

            }
            .payout{
                color: $color-primary;
            }
            .meeting-actions{
                flex-basis: 8%;
                cursor: pointer;
            }
        }
        .meeting-buttons{
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 0.25rem;
            .confirm-meeting{
                color: $color-opt-black;
                border-color: $color-opt-black;
                &:focus {
                    background-color: $color-opt-black;
                    color: $color-white;
                }
            }
            .cancel-meeting{
                cursor: pointer;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .upcoming-meeting{
        .meeting-body{
            .meeting-description{
                .meeting-details{
                    .recipient-name{
                        max-width: 150px;
                    }
                    .meeting-schedule{
                        gap: 0.25rem;
                    }
                }

                
            }
        }
    }
}
