
@import "src/stylesheets/abstracts/variables.scss";


.code {
    display: flex;
    justify-content: center;

    input {
        margin: 2rem 0.5rem 0;
        padding: 0.5rem;
        padding-bottom: 7px;
        border: none;
        border-bottom: 2px solid $color-grey-D5;
        width: 50px;
        height: 50px;
        text-align: center;
        font-size: 35px;
        font-weight: lighter;

        color: $color-grey-77;
    }

    input:focus {
        outline: none;
        
    }

    input:not(:placeholder-shown){
        padding-bottom: 0;
        border-color: #FD2C34FF;
    }


        /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
}

@media only screen and (max-width: $mobile-width) {
    .code {
        box-sizing: border-box;

        input {
            margin: 1rem 0.25rem 0;
            width: 40px;
            height: 40px;
            font-size: 30px;
        }
    }
}
