
@import "src/stylesheets/abstracts/variables.scss";


.payment-setup-container{
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    text-align: center;

    .balance-header {
        width: 100%;
        height: 10%;
        min-height: 10%;
        background-color: $color-primary;
        box-sizing: border-box;
        .header-title{
            color: $color-white;
            font-size: 1.5rem;
        }
    }

    .payment-confirm-body{
        height: 85%;
        width: 80%;
        color: $color-grey-4;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: auto;
        overflow-y: auto;

        .header{
            margin-top:5%;
            height: 12%;
            width: 100%;
            min-height: 100px;
            box-sizing: border-box;
            padding-top: 1rem;
            font-size: 2.5rem;
            // margin-bottom:2%;
        }
        .sub-text{
            height: 8%;
            width: 100%;
            min-height:80px;
        }
        .image{
            height: 70%;
            width: 100%;
            min-height:400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .btn{
            height: 10%;
            width: 100%;
            min-height: 80px;
        }
    }
}
.payment-setup-container {
    .loader-wrapper {
        position:absolute;
        left:50%;
        top:50%;
        transform:translateY(-50%)translateX(-50%);
    }
}
.payment-setup-btns{
    height: 5%;
    position:sticky;
    display: flex;
    justify-content: space-around;
    bottom: 0%;
}

@media only screen and (max-height: 1160px){
    .payment-setup-container{
        .balance-header {
            .header-title{
                font-size: 1.5rem;
            }
        }

        .payment-confirm-body{
            height: 85%;
            width: 90%;

            .header{
                margin-top: unset;
                height: 15%;
                min-height: 50px;
                padding-top: 1rem;
                font-size: 2rem;
            }
            .image{
                height: 60%;
                min-height:200px;
            }
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .payment-setup-container{
        .balance-header {
            .header-title{
                font-size: 1.5rem;
            }
        }

        .payment-confirm-body{
            height: 85%;
            width: 90%;

            .header{
                margin-top: unset;
                height: 15%;
                padding-top: .5rem;
                font-size: 2rem;
            }
            .image{
                height: 60%;
                min-height:250px;
            }
        }
    }
    .payment-setup-btns {
        
    }
}

