
@import "src/stylesheets/abstracts/variables.scss";


.tabs{
    .tabs-header{
        height: 10%;
        min-height: 70px;
        width: 100%;
        list-style-type: none;
        display: flex;
        justify-content: center;

        li{
            height: 100%;

            flex-basis: 30%;
            flex-grow: 3;

            transition: flex-basis .5s;

            background-color: $color-grey-D5;
            color: white;
            cursor: pointer;

            &:not(.selected):hover{
                // background-color: rgb(255, 164, 180) //Leaving this here for when we eventually go back to something that actually looks good
                background-color: $color-grey-3
            }

            .tab-wrapper{
                font-size: 1.25rem;
                font-weight: 400;
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 100%;

                flex-direction: column;

                .counter{
                    font-size: 1.25rem;
                    font-weight: 600
                }
                
                
                .full-title-enter-active, .full-title-leave-active, .short-title-enter-active, .short-title-leave-active{
                    transition: all .25s ease;
                }

                .full-title-enter-from{
                    opacity: 0;
                    width: 0px
                }

                .full-title-enter-to{
                    opacity: 0;
                    width: 100px;
                }

                .full-title-leave-from, .full-title-leave-to{
                    display:none;
                }

                .short-title-enter-from, .short-title-leave-to{
                    opacity: 0;
                }
                .short-title-enter-to, .short-title-leave-from{
                    opacity: 1;
                }
            }
            &.selected{
                flex-basis: 70%;
                flex-grow: 7;
                background-color: $color-red-1;
                cursor: default;

                .tab-wrapper{
                    font-size:24px;
                    font-weight: bold;

                    gap: 0.5rem;
                    flex-direction: row;
                    
                }
            }
        }
    }
}

