
@import "src/stylesheets/abstracts/variables.scss";


.payment-button-container{
    min-height:20%;
    width: 80%;
    margin: auto;

    .payment-button{
        margin: 1rem 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 2rem;
        width: 100%;
        height: auto;
        font-weight: bold;

        &:focus{
            background-color: $color-grey-1;
        }

        .payment-button-body{
            // padding: 1rem;
            width: calc(100% - 2rem);
            display: flex;
            gap: 5%;
            height: 4rem;
            text-align: left;

            &.unselected{
                height: 3rem;
                align-items: center;
            }

            .payment-icon{
                width: 4rem;
                height: 4rem;
                display: flex;
                justify-content: center;
                align-items: center;
                svg{
                    width: 3rem;
                    height: 3rem;
                }
            }

            .payment-description{
                text-align: left;
                color: $color-grey-4;
                width: calc(90% - 4rem);
                .payment-info{
                    font-size: 1rem;
                    font-weight: normal;
                }
                .payment-text{
                    font-size: 1.2rem;
                    font-weight: bold;
                }
            }
        }

        .chevy{
            transform: rotate(180deg);
        }
    }

    .info-link{
        width: 100%;
        text-align: center;
    }
}

@media only screen and (max-width: $mobile-width){
    .payment-button-container{

        .payment-button{

            .payment-button-body{
                height: 2.5rem;
                font-size: 1.2rem;

                &.unselected{
                    height: 1.5rem;
                }

                .payment-icon{
                    width: 2rem;
                    height: 2rem;
                    svg{
                        width: 2rem;
                        height: 2rem;
                    }
                }

                .payment-description{
                    .payment-info{
                        font-size: 1rem;
                        font-weight: normal;
                    }
                    .payment-text{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}
