
@import "src/stylesheets/abstracts/variables.scss";


.meeting-location-container{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    .location-container{
        min-height:16%;
        width: 80%;
        margin: auto;
        gap: 1rem;

        .location-select{
            width: 80%;
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .meeting-location-container {

        .location-container {
            width: 100%;
            box-sizing: border-box;
            padding: 0 1rem;
        }

    }
}
