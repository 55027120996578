
@import "src/stylesheets/abstracts/variables.scss";


.send-invite-contact-container {
    .content-container{
        .contact-options{
            margin-top:5%;
            display:flex;
            flex-direction: column;
            justify-content: space-around;
            padding: 0 2rem;
            height:40%;
            font-size: 18px;
            color: $color-opt-black;
            .share-number,.share-email{
                display:flex;
                align-items: center;
                &-box{
                    flex-grow:0;
                    display:block;
                    width:35px;
                    height:35px;
                }
                &-text{
                    padding-left: 2%;
                }
                .bold{
                    font-weight: bold;
                }
            }
        }

        .contact-note {
            width: 100%;
            height: 55%;
            padding: 0 2rem;
            box-sizing: border-box;
        }
    }

}
@media only screen and (max-width: $mobile-width){
    .send-invite-contact-container {
        .content-container{
            .contact-options{
                height: unset;
                gap: 1rem;
                span{
                    font-size: 1rem;
                }
            }
        }
    }
}

