
@import "src/stylesheets/abstracts/variables.scss";


.instructions-setup {
    width: 525px;
    height: 100%;

    @media only screen and (max-width:500px) {
        width: 100%;
    }
    .header-setup,
    .instructions-container,
    .subheader,
    .tmp-link,
    .description{
        width: 100%;
    }
    .header-setup {
        height: 10%;
        color: $color-primary;
    }
    .instructions-container {
        height: 85%;

        .subheader {
            button.outline{
                margin: .5rem;
                width: 380px;
                max-width: 64vw;
                min-width: 20.5rem;
                height: 60px;
                position: relative;
                color: $color-opt-black;
                @media (max-width: 400px) {
                    min-width: 18.25rem;
                }

                .chevy{
                    position: absolute;
                    right: 5%;
                    &, .chevron{
                        height: 2rem;
                        width:  2rem;
                        max-width:  4vw;
                        svg.icon.chevron path{
                            fill: $color-opt-black;
                        }
                    }
                }
                &.isActive{
                    background: $color-primary;
                    color:white;
                    border-color: darken($color-primary, 18);
                    .chevy{
                        transform: rotateX(180deg);
                        transition-duration: .25s;
                        svg.icon.chevron path{
                            fill: $color-white;
                        }
                    }
                }
            }
            .description {
                height: 70%;
                font-size: 1.2rem;
                text-align: center;
                font-size: clamp(1rem, 2vw, 1.2rem);
                margin: auto;
            }
        }
    }
    .tmp-link{
        width: 100%;
        margin-top: clamp(2px,2vh,5%);
        height: fit-content;
        .next{
            font-size: 1.5rem;
        }
    }
}
