
@import "src/stylesheets/abstracts/variables.scss";


.no-scroll{
    overflow: hidden;
}

.check-in-container, .check-in-container > .page {
    width: 100%;
    height: 100%;
}

.check-in-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity .25s ease;
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

.check-in-container .loader-container{
    height: 100%;
}

.check-in-container .error{
    text-align: center;
    color: $color-primary;
}
