
@import "src/stylesheets/abstracts/variables.scss";


.loader-container{
    width: 100%;
    height: 100%;
    position: absolute;
}

.no-scroll{
    overflow: hidden;
}

.error-container{
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    
}

.meeting-request-container, .meeting-request-container > .page {
    width: 100%;
    overflow-y: hidden;
}

.meeting-request-container{
    height:100%;
    box-sizing: border-box;
    padding: 0 2rem;
}

.meeting-request-header{
    height: 7%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: center;
}

.meeting-request-container > .page {
    height: 93%;
}

.meeting-request-container > .page .slide {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;

    .slide-header,
    .slide-container,
    .button-container{
        width: 100% !important;
    }

    .slide-header{
        height: 8% !important;
        font-size: 2rem;
        text-align: center;
    }

    .slide-content{
        height: 82% !important;
        padding: 0 2rem;
    }

    .button-container {
        height: 10% !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6%;
        }
    }
}


.meeting-request-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity 0.25s ease;
        position: relative;
        .slide{
            position: absolute;
        }
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}


