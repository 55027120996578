
@import "src/stylesheets/abstracts/variables.scss";


.auth-component {
    button{
        width:100%;
        margin-bottom:1em;
    }
    .auth-menu {
        margin-top:2em;
        list-style:none;
    }
    input {
        width:100%;
        margin-bottom:1em;
    }
    h2 {
        margin-bottom:1em;
        text-align:center;
    }
}
