
@import "src/stylesheets/abstracts/variables.scss";



.check-in-popup{
    z-index: 5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: $color-white;
    // justify-content: space-between;

    button.close-btn{
        margin: 0.5rem 1rem 0px 0px;
    }

    .loader-container{
        z-index: 10;
        background: white;

        position: absolute;
        width: 100%;
        height: 100%;
    }

    .popup-content{
        width: 100%;
        height: 80%;
        overflow-y: auto;

        .check-in {
            margin: auto;
            width: 80%;
            // height: 90%;

            &__heading {
                text-align: center;
                height: 20%;
                &--title {
                    font-size: 2.2rem;
                    color: $color-primary;
                }
                &--subheading {
                    font-size: 1.2rem;
                    line-height: .8rem;
                }
            }
            &__rating {
                height: 20%;
            }
            &__feedback {
                height: 60%;
                // padding: 1rem 5rem 1rem 5rem;
                box-sizing: border-box;
            }

            &__body{
                text-align: center;
            }
        }

        &.error-modal{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .error{
            color: $color-primary;
        }
    }

    .button-container{
        width: 90%;
        height: 15%;
        margin: auto auto 0 auto;

        display:flex;
        justify-content: space-between;
        align-items: center;

        box-sizing: border-box;

        // button{
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     width: 180px;
        // }
        .confirm-details:disabled{
            color: $color-grey-5;
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .check-in-popup{
        .popup-content{

            .check-in {
                &__rating {
                    height: unset;
                }
                &__feedback {
                    height: unset;

                    textarea{
                        height: 175px;
                    }
                }
            }
        }
    }
}
