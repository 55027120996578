
@import "src/stylesheets/abstracts/variables.scss";


.date-header{
    margin-top: 2.5rem;
    width: 100%;
    display: flex;
    align-items: center;

    $date-width: 15%;
    .meeting-date{
        width: $date-width;
        text-align: right;
        padding: 0.5rem 1rem;

        background-color: $color-primary;
        color: $color-white;
    }

    .meeting-time{
        width: calc(100% - $date-width);
        text-align: left;
        padding: 0.5rem 1rem;
    }

    
    .weekday,
    .month{
        line-height: 1.5rem;
    }

    .month{
        font-size: 1.5rem;
        text-transform: uppercase;
    }

    $date-time-size: 3rem;
    .date,
    .time{
        font-size: $date-time-size;
        font-weight: 500;
        line-height: $date-time-size
    }
}
