
@import "src/stylesheets/abstracts/variables.scss";


.calendar-settings {
    height: 100%;
    width: 100%;
    position: relative;

    &__header {
        width: 100%;
        height: 10%;
        background-color: $color-primary;
        font-size: 2.2rem;
        position: sticky;
        top: 0%;
    }

    &__google,
    &__microsoft {
        width: 100%;
        height: 40%;

        .header {
            font-size: 2rem;
            padding-bottom: 2rem;
        }
    }
    &__spacer {
        width: 100%;
        height: 40%;
    }

    &__exit {
        width: 100%;
        height: 10%;

    }

    .loader-container {
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: rgba(240, 240, 240, 0.5);
    }
}
