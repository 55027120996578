
@import "src/stylesheets/abstracts/variables.scss";


.meeting-note-container {
    height:100%;
    justify-content: center;
    // align-items: center;
    
    .note-container {
        margin-top:1rem;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 1.5rem;
        position:relative;

        .name{
            width: 90%;
        }
    }
}

@media only screen and (max-height: 800px){
    .meeting-note-container {
        .content-container {
            .note-container {
                margin-bottom: unset;
                padding: 0 1rem;
                .custom-input{
                    text-area{
                        overflow-y: auto;
                        max-height: 38vh;
                    }
                }
            }            
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .meeting-note-container {
        .content-container {
            .message-container {
                min-height: unset;
                height: unset;
            }
            .note-container {
                height: unset;
                margin-bottom: unset;
                padding: 0 1rem;

                .invite-note {
                    min-height: 9%;
                }
            }            
        }
    }
}

