
@import "src/stylesheets/abstracts/variables.scss";


.hamburger {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .line {
        position: absolute;
        width: 20px;
    }
    .line-main::before, 
    .line-main::after,
    .line-split::before, 
    .line-split::after {
        content: '';
        position: absolute;
        width: inherit;
        height: 2px;
        background-color: $color-grey-77;
        transition: transform .5s;
    }
    .line-main::before {
        transform: translateY(-7px);
    }
    .line-main::after {
        transform: translateY(7px);
    }
    .line-split::before, 
    .line-split::after {
        width: 10px;
        transition: transform .5s, opacity .5s;
    }
    .line-split::after {
        transform: translateX(10px);
    }
}

.hamburger.isChecked{
    .line-main::before {
        transform: rotate(45deg);
    }
    .line-main::after {
        transform: rotate(-45deg);
    }
    .line-split::before {
        transform: translateX(-10px);
        opacity: 0;
    }
    .line-split::after {
        transform: translateX(20px);
        opacity: 0;
    }    
}

// .hamburger.white-nav {
//     .line-main::before, 
//     .line-main::after,
//     .line-split::before, 
//     .line-split::after {
//         background-color: white;
//     }
// }
