
@import "src/stylesheets/abstracts/variables.scss";


.withdraw-submitted{
    width: 100%;
    height: 90%;
    text-align: center;

    .withdraw-submitted-header{
        padding-top:5%;
        height: 12%;
        .header-title{
            font-size: 1.5rem;
            font-weight: bold;
        }
    }

    .amount-withdrawal{
        height: 35%;
        display: flex;
        justify-content: center;
        align-items: center;
        .currency{
            font-size: 3.5rem;
        }
        .amount{
            font-size: 7rem;
        }
    }
    .extra-info{
        width: 80%;
        font-size: 1.5rem;
        margin: auto;
        .bank-name{
            font-weight: bold;
        }
    }

}
.withdraw-submitted-btn{
    display: flex;
    justify-content: center;
}

