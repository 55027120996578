
@import "src/stylesheets/abstracts/variables.scss";


.loader-container{
    width: 100%;
    height: 100%;
    position: absolute;
}

.no-scroll{
    overflow: hidden;
}
.withdraw-balance-container, .withdraw-balance-container > .page {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}
.withdraw-balance-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity .25s ease;
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}
.accountSetup{
    height: 100%;
    width: 100%;
}


