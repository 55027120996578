
@import "src/stylesheets/abstracts/variables.scss";


    .login-component{ 
        margin: auto;
    }
    .error-message{
        background: #ff4d4d;
        border-radius: 8px;
        padding: 5px;
    }
