.button-base{
    cursor: pointer;
    position: relative;
    width: 13rem;
    height: 3.5rem;
    font-family: $font-family-base;
    font-size: 1.3rem;
    background-color: transparent;
    border-color:     transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 2rem;
    box-sizing: border-box;
    overflow: hidden;
    transition: $animation-button;
    // &::after{
    //     height: 100%;
    //     width:  100%;
    //     content: "";
    //     display: block;
    //     position: absolute;
    //     top:  0px;
    //     left: 0px;
    //     border: 2px solid transparent;
    //     background-color: transparent;
    // }
    &:active,
    &:hover,
    &:focus,
    &:focus-within{
        &::after{
            background-color: rgba(lighten($color-primary, 0.8),0.2);
        }
    }
    box-shadow: unset;
    &:disabled{
        background-color: $color-opt-grey;
        outline-color: transparent;
        cursor: not-allowed;
    }
}

button.primary {
    @extend .button-base;
    background-color: $color-primary;
    color: $color-white;
    border: none;
    width: 14rem;
    height: 3.5rem;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    &:hover{
        background-color: darken($color-primary, 0.2);
    }
    &:disabled{
        background-color: $color-opt-grey;
    }
}

//Submit Button changes to a round circle with a Checkmark
button.submit {
    @extend .primary;
    transition: width .5s, border-radius .5s;
    & * {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: opacity .25s;
    }
    .icon {
        opacity: 0;
        font-size: 2.6rem;
    }
    &:focus {
        width: 48px;
        background-color: #44c08a;
        .text{
            opacity: 0;
        }
        .icon {
            opacity: 1;
            transition-delay: .5s;
        }
    }
}
//End Submit

button.outline {
    @extend .button-base;
    background-color: transparent;
    color: $color-primary;
    border: 2px solid $color-primary;
    border-radius: 2rem;
    border-width: 2px;
    &:focus,
    &:focus-within,
    &:active {
        background-color: rgba(lighten($color-primary, 0.8),0.2);
        // color: $color-white;
    }
    &:hover{
        border-width: 2px;
        color: white;
        &,&::after{
            background-color: rgba(darken($color-primary, 10%),1);
        }
    }
    &:disabled{
        background-color: $color-disabled;
        color: $color-primary;
    }
}

button.sm-outline {
    @extend .outline;
    height: 2.25rem;
}

button.splash {
    @extend .button-base;
    background-color: $color-grey-4;
    color: $color-white;
    border: none;
    border-radius: 2rem;
    &:focus,
    &:active,
    &:focus-within {
        background-color: $color-primary;
        color: $color-white;
    }
}

button.borderless, button.text {
    @extend .outline;
    @extend .splash;
    position: relative;
    font-size: 1.3rem;
    color: $color-primary;
    border:none;
    border-color: transparent;
    padding-block: .2rem;
    width: auto;
    min-width: 7rem;
    background-color: transparent;
    &:focus,
    &:active,
    &:focus-within,
    &:focus-visible {
        background-color: rgba($color-primary, 0.2);
        color: $color-opt-black;
    }
    &:disabled{
        background-color: $color-disabled;
        color: $color-primary;
    }
}

button.text-button,
button.link{
    @extend .outline;
    @extend .primary;
    color: $color-opt-blue;
    max-width: unset;
    min-width: max-content;
    padding-inline: unset;

}


button.text-button.link{
    @extend .opt-text-small;
    border:none;
    color: $color-opt-black;
    background-color: transparent;
    max-width: max-content;
    padding: 0;
    margin-left: unset;
    &:hover{
        text-decoration: underline;
    }
    &:focus, &:active, &:focus-within{
        outline-color: transparent;
    }
}

/** Nav bar buttons **/
button.nav-btn,
button.nav-btn.opt-logo,
button.nav-btn.profile{
    cursor: pointer;
    width: 13rem;
    font-size: 1.3rem;
    background-color: transparent;
    border-color:     transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    border-radius: 2rem;
    box-sizing: border-box;
    // position:     absolute;
    height:        6.25rem;
    min-width:     6.25rem;
    padding:      0.825rem;
    .icon.profile{
        height:   2.125rem;
        width:    2.125rem;
    }
    &:focus,&:active,&:focus-within{
        outline: 2px solid transparent;
    }
}

.abs-position{
    position: absolute;
    top: 0px;
    right: 0px;
}

button.close-btn {
    @extend    .borderless;
    @extend  .abs-position;
    box-sizing: border-box;
    width:         2.75rem;
    height:        2.75rem;
    max-width:     2.75rem;
    max-height:    2.75rem;
    min-width:        44px;
    min-height:       44px;
    margin:          unset;
    padding:          14px;
    border-radius:     50%;
    svg{
        height:       14px;
        width:        14px;
        display:     block;
        margin:       auto;
    }
}

button.back-btn {
    @extend .borderless;
}