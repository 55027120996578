
@import "src/stylesheets/abstracts/variables.scss";


.rate-settings{
    margin: auto;
    width: 100%;
    height: 100%;
    overflow: auto;

    .rate-settings-container {
        width: 85%;
        height: 85%;
        overflow: auto;
        .title{
            display:flex;
            padding-top:8%;
            box-sizing: border-box;
            font-size: 30px;
            color: $color-grey-77;
            font-weight: 500;
            text-align: center;
            // background:blue;
        }
        .rate-description{
            width: 75%;
            line-height: 1.5rem;
            margin: auto;
            text-align: center;

            .subText{
                font-size: 16px;
            }
        }
        .rate-description2{
            width: 75%;
            display: flex;
            justify-content: center;
            margin: auto;
            text-align: center;
            color: $color-grey-4;
            line-height: 1.5rem;
            font-size: 16px;
            padding-bottom: 5%;
            box-sizing: border-box;

            .subText{
                text-align: center;
            }
        }

        .standard-meeting-rate-container,
        .standard-meeting-time-container{
            color: $color-grey-3;;

            .rate-title,
            .time-title {
                font-size: 1.8rem;
                color: $color-grey-77;
            }
            .payment-container,
            .time-subcontainer {
                display: flex;
                width: 9rem;
                justify-content: center;
                align-items: center;
                font-size:3rem;
                font-weight: 900;
                color: $color-grey-4;
                border-bottom: solid 1px $color-grey-77;

                .paymentAmount,
                .timeAmount {
                    width: 100%;
                    color: $color-grey-4;
                    font-size: 5.5rem;
                    text-align: center;
                    border:none;
                    font-weight: 900;
                }
            }
        }
    }
    .rate-settings-button{
        width: 100%;
        // height: 15%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .save-amount{
            padding: unset;
            font-size: 1rem;
            width: 150px;
            border: 2px solid $color-primary;
        }
        .back{
            padding: unset;
            font-size: 1rem;
            width: 150px;
        }
    }    
}

@media only screen and (max-width: $mobile-width){
    .rate-settings{
        .rate-settings-container {
            width: 95%;

            .title{
                padding-top: unset;
                font-size: 2rem;
            }
            .rate-description,
            .rate-description2 {
                width: 100%;
            }
            .standard-meeting-rate-container,
            .standard-meeting-time-container {
                .payment-container,
                .time-subcontainer {
                    .symbol {
                        font-size: 2rem;
                    }
                    .paymentAmount,
                    .timeAmount {
                        font-size: 2.5rem;
                    }
                }
            }
            .rate-description{
                min-height: 170px;
                width: 90%;
            }
            .rate-description2{
                width: 90%;
            }
        }
        .rate-settings-button {
            flex-direction: row;
            padding-bottom: 4rem;
        }
    }
}
