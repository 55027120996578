
@import "src/stylesheets/abstracts/variables.scss";


.ready{
    .header{
        color: $color-grey-77;
        font-size: 100px;
        font-weight: 300;
    }

    .description{
        color: $color-grey-77;
        font-size: 30px;
        font-weight: 300;
    }

    aside {
        line-height:1.5em;
        margin-top:3em;
        h3 {
            color:lightgray;
            font-weight:normal;
            margin:0;
        }
        p {
            margin-block-start: 0;
            margin-block-end: 0;
        }
    }

    .button-container-setup-ready {
        margin-top: 15%;
        width: 100%;
        .help-btn{
            font-size: 25px;
            margin-bottom:1rem;

            &:hover {
                color: $color-primary;
            }
        }
        .send-invite{
            font-size: 30px;
        }

        .continue-setup{
            width: unset;
            font-size: 1rem;
        }
        .back{
            font-size: 1rem;
            color: $color-primary;
        }
    }
}
