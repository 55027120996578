
@import "src/stylesheets/abstracts/variables.scss";


.toast-enter-from{
    opacity: 0;
    transform: translateY(60px);
}
.toast-enter-active{
    transition: all 0.3s ease;
}
.toast-leave-to{
    opacity: 0;
    transform: translateY(60px)
}
.toast-leave-active{
    transition: all 0.3s ease;
}
.toast-container{
    z-index: 25;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    bottom: 20px;
    .toast-message{
        font-size: 1.25rem;
        text-align: center;
        padding: 20px;
        color: white;
        background: $color-primary;
        border-radius: 1rem;
        box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.25);
        width: 400px;
        // min-width: 400px;
        margin: 0 auto;
    }
}

