
@import "src/stylesheets/abstracts/variables.scss";


.confirm-meeting-container {
    // position: relative;

    .loader-container{
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        display: flex;
        align-items: center; 
        justify-content: center;
    }
    .request-container-invite {
        display: flex;
        flex-direction: column;
        height:100%;
        width: 100%;

        .message-container{
            width: 80%;
            font-size: 1.25rem;
            margin: 1rem auto;
        }
        .content-container{
            .link-container {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                .invite-container {
                    width: 100%;

                    .url-container,.preview-container{
                        display:flex;
                        justify-content: center;
                        width:80%;
                        height:50px;
                        position:relative;
                        border-radius: 10px;
                        border: solid 2px #f0f0f0;
                        .img-wrapper{
                            display:flex;
                            width:15%;
                            justify-content: center;
                            align-items: center;
                            .copyIcon{
                                width:35px;
                            }
                        }
                    
                        .url{
                            border:none;
                            color: $color-blue-1;
                            width:85%;
                            font-weight: bold;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis
                        }
                        .preview{
                            display:flex;
                            border:none;
                            width:85%;
                            align-items: center;
                            justify-content: center;
                            font-size: 14px;
                        }
                    }
                    .preview-container{
                        margin-top:2%;
                        .img-wrapper{
                            display:flex;
                            justify-content: flex-start;
                            width:25%;
                            .previewIcon{
                                padding-left:16%;
                                width:35px;
                            }
                        }
                    }
                }

            }
            .buttons {
                margin-top:2%;
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;

                .copy {
                    font-size: 1rem;
                    position: relative;
                    width: 180px;
                    height: 50px;

                    .text {
                        width: 80%;
                        padding-right: 2rem;
                    }

                    .copyImage {
                        width: auto;
                        height: auto;
                        margin-left: 35%;
                        position: absolute
                    }
                    &:focus {
                        .copyImage {
                            opacity: 0;
                        }
                    }
                }
                .share {
                    position: relative;
                    width: 180px;
                    height: 50px;

                    .shareText {
                        font-size: 1.25rem;
                    }
                    .shareImage {
                        position: absolute;
                        right: 5%;
                    }
                }
            } 
        }
        .button-container {
            height: 15%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .toHome, .createInviteLink{
                color: $color-red-1;
                text-align: center;
                text-decoration: none;  
                font-size: 1rem;

            }
        }
        .preview-overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $color-white;
            &.v-enter-active, &.v-leave-active {
                transition: transform 0.25s ease;
            }
            &.v-enter-from {
                transform: translateY(100%);
            }
            &.v-leave-to {
                transform: translateY(100%);
            }
        }
    }
}

@media only screen and (max-height: 875px){
    .confirm-meeting-container{
        .request-container-invite{
            .content-container{
                .qr-code-container{
                    display: none;
                }
            }
        }
    }
}

@media only screen and (max-height: 815px){
    .confirm-meeting-container{
        .request-container-invite{
            .button-container{
                height: 16%;
            }
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .confirm-meeting-container{
        .request-container-invite{
            .link-container {
                .invite-container {
                    .url-container,
                    .preview-container {
                        .url {
                            padding-left: 2.5rem;
                            font-size: 1rem;
                        }
                        .preview {
                            padding-left: 2.4rem;
                        }
                        .copyIcon {
                            left: 3%;
                        }
                        .previewIcon {
                            left: 3%;
                        }
                        input {
                            width: 90%;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }

                    }
                }
            }
            .buttons {
                margin-top: auto;
            }
        }
    }
}
