
@import "src/stylesheets/abstracts/variables.scss";



.meetings.center{
    height: $card-height;
    font-size: 1rem;
    flex-direction: column;
    width:100%;

    .meetings-header {
        height: 4em;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-primary;
        color: $color-white;
        font-weight: 500;
    }

    .meeting-tabs{
        height: 87%;
        width: 100%;
        box-sizing: border-box;
        // background: blue;

        .tab{
            width: 100%;
            position: relative;
            height: 90%;
            padding-top:8%;
            // padding: 2rem;
            box-sizing: border-box;
            overflow-y: auto;

            .schedule-meetings-container{
                width: 100%;

                .section-header{
                    position: relative;
                    display:flex;
                    justify-content: space-between;
                    .section-title{
                        width: 40%;
                        padding-left: 5%;
                        font-size: 18px;
                        margin-top:2rem;;
                    }
                    .line{
                        height: 2.85rem;
                        width:60%;
                        border-bottom: 1px solid $color-opt-grey;
                    }

                }
            }

            &::-webkit-scrollbar {
                width: 0.5em;
                background: none;
                position: relative;

                &-track{
                    background: rgb(156, 156, 156, 0.25);
                    border-radius: .25em;
                }
                &-thumb {
                    background: rgb(156, 156, 156, 0.75);
                    width: 1em;
                    border-radius: .25em;
                }
            }
            .loader-container{
                top: 0;
                z-index: 2;
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .noMeetings{
                width: 100;
                height: 9%;
                display: flex;
                justify-content: center;
                align-items: center;
                .no-meeting-message{
                    text-align: center;
                    font-size: 30px;
                    display:inline-block;
                    padding:1em;
                    margin-top:2em;
                }
            }
        }
    }
    .meetings-bottom {
        position:absolute;
        bottom: 0;
        height: 12%;
        width: 100%;
        flex-direction: row;
        justify-content: space-around;
        box-sizing: border-box;

        .return{
            padding: 0;
        }
    }
    @media only screen and (max-width: 500px){
        .meeting-tabs .tab{
            padding: 0;
        }
        .btn-container.bottom{
            height: 20%;
            padding-top: 5%;
        }
    }
}

.changeMeeting{
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: $mobile-width){
    .meetings.center {
        .meeting-tabs {
            .tab {
                // height: unset;
                .noMeetings {
                    .no-meeting-message {
                        padding: 1rem 1.5rem;
                        box-sizing: border-box;
                        font-size: 1.5rem;
                    }                    
                }

            }
        } 
    }
}


