
@import "src/stylesheets/abstracts/variables.scss";


.time-selector-container {
    .slide-content{
        .scroller{
            height:65%;
        }
        .selected-date{
            // margin-top: 2rem;
            .header{
                font-size: 1rem;
            }
            .date{
                font-size: 1.5rem;
            }
        }
    }
}
@media only screen and (max-width: $mobile-width) {
    .time-selector-container {
        padding: unset;
        margin:unset;
        .slide-content {
            .scroller {
                height: 20rem;
                padding: 0 1.5rem;
            }
            .selected-date {
                width: 100%;
                margin-top: unset;
                padding-top: 1rem;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
            }
        }
        .button-container {
            padding-bottom: 3rem;
            box-sizing: border-box;
        }
    }
}
