
@import "src/stylesheets/abstracts/variables.scss";


.add-profile-pic-container{
    width: 85%;
    height: 100%;
    margin: auto;

    .title{
        font-size: 2.5em;
        color: $color-opt-black;
        text-align: center;
    }

    .description{
        margin: auto;
        text-align: center;
        line-height: 14pt;
        width: 75%;
        font-size: 1.4em;
        padding-top: 2.5%;
    }

    .profile-pic{
        margin: auto;
        margin-top: 3%;
        margin-bottom: 1%;
        height: 50%;
        width: 100%;
        .progress-container{
            height: 80%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 40px;
        }
        .picture-container{
            display: flex;
            justify-content: center;
            height: 80%;

            svg{
                width: 100%;
                height: 100%;
            }
            .temp-picture{
                width: clamp(100px, 30vw, 200px);
                height: clamp(100px, 30vw, 200px);
                font-size: clamp(40px, 13vw, 80px);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                background-color: $color-grey-D5;
                color: white;
            }
            .uploaded-image{
                width: clamp(100px, 30vw, 200px);
                height: clamp(100px, 30vw, 200px);
                font-size: clamp(40px, 13vw, 80px);
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                border: 1px solid $color-opt-black;
                object-fit: cover;
            }
        }
        .picture-buttons{
            margin-top: 5%;
            height: 15%;

            .upload{
                cursor: pointer;
            }
        }
    }
    .button-container{
        margin-top: 3%;
        display: flex;
        flex-direction: column;
        gap: 1rem;

        .continue{
            padding: unset;
            font-size: 1em;
            width: 180px;
        }
    }
}

