
@import "src/stylesheets/abstracts/variables.scss";


.terms-of-service{
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-content: space-between;
}

