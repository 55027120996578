
@import "src/stylesheets/abstracts/variables.scss";


.link-share-popup{
    z-index: 1;
    position: absolute;
    bottom: 0;
    background-color: $color-white;
    width: 100%;
    

    .link-share-popup-container{
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 70%;
        margin: auto;
        margin-bottom: 0.5rem;
    }

    button{
        width: 100%;
    }
    .close-button{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;

        cursor: pointer;
    }

    .qr-code{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}
