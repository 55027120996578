
@import "src/stylesheets/abstracts/variables.scss";


.meeting-type-container{
    .content-container{
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        padding: 0 2rem;
        min-height: 60%;
        .message-container {
            margin: 2rem auto;
            font-weight: 400;
            font-size: 1.4rem;
            text-align: center;
            color: $color-opt-black;

        }
        .type-button-container {

            display: flex;
            flex-direction: column;
            align-items: center;

            box-sizing: border-box;

            color: $color-primary;
            font-size: 1.5rem;

            .buttons {
                display: flex;
                flex-direction: column;
            }
            .action-title {
                font-size: 2rem;
                margin-bottom: 1rem;
            }
            .black-outline {
                // @extend .outline;
                // border: solid 2px $color-grey-3;
                margin-bottom: 1rem;
                // font-size: 1.25rem;
                // padding: 1.2rem;
                // height: unset;
                // width: unset;
                white-space: nowrap;
            }
            .prevPage {
                width: 70%;
                height: unset;
                padding: 2.5% 0;
                text-align: center;
                font-size: 1.25rem;
                color: $color-blue-2;
                text-decoration: none;

                &:focus {
                    color: white;
                }
            }
        }
    }

    .button-container{
        justify-content: center;
    }
}
