
@import "src/stylesheets/abstracts/variables.scss";


.payment-info-processing{
    width: 100%;
    height: 98%;
    box-sizing: border-box;
    text-align: center;
    overflow-y: hidden;

    .balance-header {
        width: 100%;
        height: 12%;
        background-color: $color-primary;
        box-sizing: border-box;

        .header-title{
            color: $color-white;
            font-size: 1.5rem;
        }
    }

    .withdraw-page-body{
        height: 75%;
        color: $color-grey-4;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: auto;
        .header{
            margin-top:5%;
            min-height: 80px;
            font-size: 2.5rem;
        }
        .sub-text{
            min-height:100px;
        }
        .image{
            min-height:400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}
.payment-process-btns{
    position: sticky;
    bottom: 0;
}

@media only screen and (max-height: 900px) {
    .image {
        width: 80%;
    }
    .payment-info-processing {
        overflow: hidden;
        .withdraw-page-body {
            .sub-text {
                min-height: 0;
            }
        }
    }
}
