
@import "src/stylesheets/abstracts/variables.scss";


.no-scroll{
    overflow: hidden;
}
.referral-container, .referral-container > .page {
    width: 100%;
    height: 100%;
}
.referral-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity .25s ease;
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}

