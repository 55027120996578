
@import "src/stylesheets/abstracts/variables.scss";


.confirm-meeting-container {
    // position: relative;

    .loader-container{
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        border-radius: 1rem;
        display: flex;
        align-items: center; 
        justify-content: center;
    }
    .request-container-invite {
        display: flex;
        flex-direction: column;
        height:100%;
        width: 100%;
    }
}
