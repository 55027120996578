
@import "src/stylesheets/abstracts/variables.scss";


.location-select-container {
    .location-selector {
        height: calc(3rem + 2px);
    
        .select-container{
            display: grid;
            grid-template-areas: 
                "select"
                "options";
            align-items: center;
            border: 1px solid $color-opt-red;
            border-radius: 25px;
            box-sizing: border-box;
            background: white;
            position: relative;
            z-index: 1;
            
            .options-container {
                max-height: 12rem;
                &.grow-in-enter-active, &.grow-in-leave-active {
                    transition: max-height .15s ease;
                    overflow: hidden;
                }
                &.grow-in-enter-from, &.grow-in-leave-active {
                    max-height: 0px;
                }
            }
            
            .option {
                grid-area: select;
                cursor: pointer;
                font-weight: bold;
                display: grid;
                grid-template-areas: "option";
                align-items: center;
                text-align: center;
                min-height: 3rem;
    
                .display, .icon {
                    grid-area: option;
                }
                .icon {
                    justify-self: start;
                    height: 2em;
                    aspect-ratio: 1 / 1;
                    margin-left: 1em;
                    margin-top: .5em;
                    margin-bottom: .5em;
                }
    
                &.unselected {
                    color: $color-opt-red;
                }
            }
            
            &::after {
                content: '';
                width: 1em;
                aspect-ratio: 3 / 2;
                background-color: $color-opt-black;
                display: block;
                clip-path: polygon(0% 0%, 50% 65%, 100% 0%, 100% 35%, 50% 100%, 0% 35%);
                justify-self: end;
                margin-right: 1em;
                transition: transform .25s ease;
            }
            &.focused::after {
                transform: rotate(180deg);
            }
            &::after {
                grid-area: select;
            }
        }
    
    }
    
    .field-container {
        display: grid;
        grid-template-columns: auto auto;
        gap: 1rem;
        margin: 1rem 0;

        .input-field {
            margin: unset;
            grid-column: 2 span;

            &.half-width {
                grid-column: unset;
            }
            &.full-height {
                grid-row: 2 span;
            }
        }
    }
}
