
@import "src/stylesheets/abstracts/variables.scss";


$font-weight-regular      : 400;
$font-weight-medium       : 500;
$font-weight-semi-bold    : 600;
$font-weight-bold         : 700;

.default-rate{
    margin: -1.625rem auto calc(28px - 65.7px ) auto;
    width: 85%;
    height: 100%;
    .title,
    .description,
    .subText,
    .rate-container,
    .rate-title,
    .rate-subtext,
    .input-container,
    .payment-container,
    .payment-container,
    .input-hint,
    input,
    .rate-subtext{
        font-size: 1em;
        color: $color-opt-black;
        text-align: center;
    }

    .title{
        font-size: 1.375rem;
        font-weight: $font-weight-bold;
    }
    .rate-title, .rate-subtext{
        font-size: 1.25rem;
        font-weight: $font-weight-medium;
    }
    .currency-symbol,
    .payment-amount{
        line-height: 4.75rem;
    }
    .currency-symbol{
        font-size: 2.5rem;
    }
    .payment-amount{
        font-size: 4.125rem;
        font-weight: bolder;
    }
    .rate-subtext,
    .description-text{
        font-size: 1.5rem;
    }

    .input-hint{
        font-size: 0.5rem;
    }

    .subText,
    .description{
        line-height: 0.9375rem;
        width: 18rem;
        font-size: 0.75rem;
        margin: 0.125rem auto;
    }
    .rate-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-block: 2rem 2.25rem;
        .input-container{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 5%;
            .payment-container{
                min-width: 3rem;
                max-width: 8.5rem;
                display: flex;
                justify-content: center;
                font-size:3em;
                font-weight: bold;
                outline: solid 2px transparent;
                border-radius: .25rem;
                position: relative;
                .payment-amount{
                    // background-color: rgba(255, 0, 0, 0.315);
                    min-width: 100%;
                    max-width: 100%;
                    max-width: calc((40px * var(--string-length)) + 16px);
                    border:  solid 1px transparent;
                    border-bottom-color: $color-opt-black;
                }
                &.shift-left {
                    transform: translateX(-0.83rem);
                }
            }
            .text-width-1{
                width: 3rem;
            }
            .text-width-2{
                width: 5.5rem
            }
            .text-width-3{
                width: 8.5rem;
            }
        }
    }
    .button-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: column;
        gap :0.75rem;
        margin-block: 1.5rem 0px;
        .save-amount,
        .back-btn{
            // font-size: 0.75rem;
            width: fit-content;
            color: $color-primary;
            // height: 2.25rem;
            padding: 0.5rem 2rem;
        }
    }
@media only screen and (max-width: 600px) {
    .amount-container,
    .time-container{
        width:30%;
        .currency-symbol,
        .paymentAmount{
            font-size: 2rem;
        }
    }
}
@media only screen and (max-height: 800px) {
    margin: 0px auto 2rem auto;
}
}
