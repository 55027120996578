
@import "src/stylesheets/abstracts/variables.scss";


.meeting-confirm-container {
    .slide-content{

        .info-container{
            height: 50%;
            color: $color-opt-black;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2rem;
            .info{
                display:flex;
                flex-direction: column;
                justify-content:center;

                &-data{
                    font-size: 1.5rem;
                }
            }
        }

        .payment-container{
            margin-top: 3rem;
            height: 30%;
            .payment-button-container{
                width: 100%;
            }
        }
    }
 
}
@media only screen and (max-width: $mobile-width) {
    .meeting-confirm-container {
        padding: 25px;
        padding-top: 0;
        box-sizing: border-box;

        .slide-content {
            .info-container {
                font-size: 1rem;
                gap: 1rem;
                height: unset;
                // padding-top: 2.5rem;
                box-sizing: border-box;

                .info-location {
                    display: flex;
                    justify-content: flex-start;              
                }
            }

            .payment-container{
                margin-top: 1rem;
            }
        }
    }
}
