
@import "src/stylesheets/abstracts/variables.scss";


.payment-setup{
    width: 85%;
    height: 100%;
    margin: auto;
    .title{
        font-size: 2em;
        color: $color-opt-black;
        text-align: center;
    }
    .description{
        margin: auto;
        text-align: center;
        line-height: 14pt;
        width: 75%;
        font-size: 1rem;
    }
    .subText{
        font-size: 1em;
        color: $color-opt-black;
        text-align: center;
    }
    .rate-container{
        font-size: 1em;
        color: $color-opt-black;;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top:5%;
        margin-bottom: 5%;

        .rate-title{
            font-size: 1.8em;
            color: $color-opt-black;
        }
        .payment-container{
            display: flex;
            justify-content: center;
            font-size:3em;
            font-weight: bold;
            color: $color-grey-4;
            border-bottom: solid 1px $color-primary;
            width: 28%;

            .paymentAmount{
                color: $color-opt-black;
                font-size:4rem;
                text-align: center;
                border:none;
                width: 100%;
                font-weight: bolder;
            }
        }
        .editAmount{
            color: $color-opt-black;
        }
    }
    .bottom-description{
        margin: auto;
        text-align: center;
        line-height: 14pt;
        width: 75%;
        margin-bottom:5%;
    }
    .button-container{
        display: flex;
        justify-content: center;
        .save-amount{
            padding: unset;
            font-size: 1rem;
            width: 150px;
        }
    }
}
