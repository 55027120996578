
@import "src/stylesheets/abstracts/variables.scss";


.withdraw-form{
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    text-align: center;
    .balance-header {
        width: 100%;
        height: 12%;
        background-color: $color-primary;
        box-sizing: border-box;
        .header-title{
            color: $color-white;
            font-size: 1.5rem;
        }
    }
    .withdraw-page-body{
        height: 75%;
        color: $color-grey-4;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }

}
