
@import "src/stylesheets/abstracts/variables.scss";



.availability-setup{
    &.setup{
        align-items: center;
    }
    padding:unset;
    // padding: clamp(1rem, 3vh, 3rem) clamp(1rem, 4vw, 5rem);
    .header{
        font-size: 30px;
        font-weight: 600;
        color: $color-grey-77;
        margin-bottom: 2%;
    }
    .description{
        font-size: 20px;
        font-weight: 400;
        color: $color-grey-77;
        margin-bottom: 10%;
    }
    .description2{
        font-size: 16px;
        font-weight: 400;
        color: $color-grey-77;
    }

    .input-container{
        width: 100%;

        .input-description{
            margin-bottom: 2%;
        }
        .week-checkboxes{
            padding-bottom: 1.5rem;
            width: 100%;
            display: flex;
            justify-content: space-between;

            .day-option{
                display: flex;
                flex-direction: column;
            }
        }
    }

    .availability-setup-buttons{
        width: 50%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .progress{
            font-size: 1.25rem;
        }
        .back{
            color: $color-primary;
            font-size: 1.25rem;
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .availability-setup {
        .header {
            font-size: 1.3rem;
        }
        .availability-setup-buttons {
            width: 100%;
            flex-direction: column;
        }
    }
}
