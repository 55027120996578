
@import "src/stylesheets/abstracts/variables.scss";


.setup {
    &, .b {
        width: 100vw;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .setup-loader {
        position: absolute;
        z-index: 1;
        background-color: rgba(226, 226, 226, 0.35);
    }
    .page, .page2{
        font-size: clamp(0.5rem, 3vw, 1rem);
        background-color: white;
        box-sizing: border-box;
        padding: clamp(2rem, 6vh, 6rem) clamp(2rem, 4vw, 6rem);
        border-radius: 3rem;
        align-items: center;
        text-align: center;
        justify-content: space-evenly;
        width: clamp(250px, 80vw, 750px);
        .setup {
            font-size: 1em;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
        }
        .header-setup, .header-1{
            margin-bottom: clamp(0.5rem, 2vh, 2rem);
        }
    }
    .callendar-setup>.page,
    .secondary-setup>.page {
        &.v-enter-active,
        &.v-leave-active {
            transition: all .5s ease;
        }

        &.v-enter-from {
            transform: translateX(200%);
            opacity: 0;
        }

        &.v-leave-to {
            transform: translateX(-200%);
            opacity: 0;
        }
    }
    @media only screen and (max-height:700px) {
        &, .b {
            align-items: unset;
            .page2 {
                width: 100%;
                height: 100%;
                padding: 2rem 1.5rem;
                border-radius: unset;
            }
            .page, .page2{
                max-height: 100vh;
            }
        }
    }
    @media only screen and (max-width: $mobile-width) {
        .tetiary-setup {
            .page2 {
                width: 100%;
                height: 100%;
                padding: 2rem 1.5rem;
                border-radius: unset;
            }
        }
        .page, .page2{
            max-height: 100vh;
            top: 0;
            height: 85vh;
            min-height: 85vh;
        }
        .secondary-setup {
            .page {
                width: 100%;
                height: 100%;
                border-radius: unset;
            }
        }
    }
}
