
@import "src/stylesheets/abstracts/variables.scss";


.g__loader-container {
    width: 100%;
    height: 100%;
    position: relative;

    .g__loader {
        position: absolute;
        width: 20%;
        height: 20%;
        top: 40%;
        border-radius: 50%;
        background: $color-opt-red;
        animation-timing-function: cubic-bezier(0, 1, 1, 0);

        &:nth-child(1) {
            left: 0%;
            animation: ellipsis1 0.6s infinite;
        }
        &:nth-child(2) {
            left: 0%;
            animation: ellipsis2 0.6s infinite;
        }
        &:nth-child(3) {
            left: 40%;
            animation: ellipsis2 0.6s infinite;
        }
        &:nth-child(4) {
            left: 80%;
            animation: ellipsis1 0.6s infinite reverse;
        }

        @keyframes ellipsis1 {
            0% { transform: scale(0); }
            100% { transform: scale(1); }
        }
        @keyframes ellipsis2 {
            0% { transform: translate(0, 0); }
            100% { transform: translate(200%, 0); }
        }
    }
}
