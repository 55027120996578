
@import "src/stylesheets/abstracts/variables.scss";


.referral-start{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;

    .title{
        font-size: 4rem;
        padding-top: 10%;
        height: 20%;
    }
    .image{
        height: 30%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-opt-black;
    }
    .description{
        padding-top: 10%;
        height: 30%;
    }
    .btn-start{
        height: 10%;

        .toHome{
            font-size: 1rem;
        }
    }
}

@media only screen and (max-height: 975px) {
    .referral-start {

        .title {
            font-size: 3rem;
        }
        .description {
            padding-top: 1%;
        }
    }
}
