
@import "src/stylesheets/abstracts/variables.scss";


.referral-send{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;

    &.loading{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .title{
        font-size: 2.5rem;
        padding-top: 10%;
        height: 10%;
    }

    .line-break{
        width: 80%;
        border-bottom: 1px solid red;
    }

    .description{
        height: 20%;
        padding-top: 10%;
        box-sizing: border-box;
    }

    .link-container{
        box-sizing: border-box;
        padding-top: 20%;
        height: 55%;
        min-height: 330px;
        width: 90%;

        .link-header{
            font-size: 2rem;
            color: $color-red-1
        }
        input {
            padding: 2.5%;
            width: 90%;
            text-align: center;
            border: solid 1px $color-grey-2;
            color: $color-grey-4;
            box-shadow: 1px 1px 4px rgba(0, 0, 0, .2);
        }
        .primary-buttons,
        .secondary-buttons {
            padding-top: 10%;
            display: flex;
            margin: auto;
            width: 85%;
            justify-content: space-around;
            gap: 5%;

            button{
                width: 10rem;
            }
            .copy {
                font-size: 1rem;
                position: relative;
                width: 50%;
            }
            .share {
                position: relative;

                .shareText {
                    font-size: 1.25rem;
                }
                .shareImage {
                    position: absolute;
                    right: 5%;
                }
            }
        }
    }
    .bottom-referral{
        height: 10%;
        min-height: 80px;
        display: flex;
        justify-content: center;
    }
}

@media only screen and (max-height: 975px) {
    .referral-send {

        .title {
            padding-top: 1%;
            padding-bottom: 2%
        }
        .description {
            padding-top: 1%;
        }
        .link-container {
            padding-top: 5%;
        }
    }
}
