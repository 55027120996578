
@import "src/stylesheets/abstracts/variables.scss";


.check-in-success{
    width: 100%;
    height: 100%;
    padding: 5% 10% 0;
    box-sizing: border-box;
    text-align: center;

    .title{
        height: 15%;
        min-height: 100px;
        color: $color-opt-black;
        font-size: 4rem;
        box-sizing: border-box;
    }

    .message {
        height: 20%;
        min-height: 70px;
        font-size: 1.5rem;
        box-sizing: border-box;
    }

    .description{
        height: 50%;
        min-height: 150px;

        .name{
            font-weight: bold;
        }
    }

    .button-container-checkin{
        height: 10%;
        padding-bottom: 0;
        
        .complete-check-in{
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .check-in-success {
        .mesasge {
            height: 15%;
        }
        .description {
            height: 40%;
        }
        .button-container-checkin {
            height: 20%;
            padding-bottom: 3rem;
        }
    }
}
