
@import "src/stylesheets/abstracts/variables.scss";


.invite-offer{
    width: 100%;
    height: $card-height;

    .link-in-use{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
