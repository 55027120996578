
@import "src/stylesheets/abstracts/variables.scss";


.checkbox{
    position: relative;
    appearance: none;
    cursor: pointer;
    border: solid 1px $color-grey-D5;
    width: 33px;
    height: 33px;
    transition: all 0.3s;

    &.isActive{
        background-image: url("@/resources/checkmark2.svg");
        background-repeat: no-repeat;
        background-position:center;
        background-size: contain;
    }
}
