html {
    @extend .default-text;
    font-size: 16px;
    // @media only screen and (min-width: 1000px) and (max-width: 1300px){
    //     font-size: 10px;
    // }
}

body {
    margin: unset;
    box-sizing: border-box;
}

// Safari has special padding it applies to lists that no one else does >:(
ul, ol {
    padding-inline-start: 0;
    margin-block-start: 0;
    margin-block-end: 0;
}

.soft-shadow{
    -webkit-box-shadow: 0.4375rem 0.4375rem 0.625rem 0rem $color-card-shadow;
       -moz-box-shadow: 0.4375rem 0.4375rem 0.625rem 0rem $color-card-shadow;
            box-shadow: 0.4375rem 0.4375rem 0.625rem 0rem $color-card-shadow;
}