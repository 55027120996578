
@import "src/stylesheets/abstracts/variables.scss";



.scrim .modal header {
    display:flex;
    flex-direction:row;
    h3 {
        flex-grow:3;
    }
    .close {
        width:20px;
    }
}
