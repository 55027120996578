
@import "src/stylesheets/abstracts/variables.scss";


.date-selector-container {
    .slide-content{
        // margin-top: 15%;
        .selected-date{
            display:flex;
            flex-direction: column;
            justify-content: flex-end;
            height:20%;
            .header{
                font-size: 1rem;
            }
            .date{
                font-size: 1.5rem;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    .date-selector-container {
        padding: 1rem;

        .slide-content {
            .selected-date {
                width: 100%;
            }
        }
    }
}

