
@import "src/stylesheets/abstracts/variables.scss";



.feedback-main-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    padding-top: 3rem;
    box-sizing: border-box;
    justify-content: unset;
    z-index: 50;
    background-color: rgba(185, 185, 185, 0.8);
    .feedback-form {
        width: 32.8125rem;
        height: calc(100vh - $app-bar-height);
        min-height: 29.25rem;
        max-height: calc(100% - 6.25rem);
        box-sizing: border-box;
        padding: 1rem 3rem;
        border-radius: 40px;
        background-color: $color-white;

        .feedback-button-container {
            width: 100%;
            height: 5%;
            display: flex;
            justify-content: flex-end;
            
            .close {
            width: 4%;
            }        
        }
        .heading {
            width: 100%;
            height: 20%;
        }
        .input-container {
            width: 95%;
            height: 65%;

            .name, .message {
                width: 100%;
            }
        }
        .button-container {
            width: 100%;
            height: 10%;
        }
    }
}
.loading-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: $color-opt-grey;
    z-index: 10;
}

@media only screen and (max-height: 770px) {
    .feedback-main-container {
        .feedback-form {
            .heading {
                .feedback-header {
                    font-size: 2rem;
                }
                .feedback-description {
                    font-size: 1.3rem;
                }
            }
            .input-container {
                .message {
                }
            }
        }
    }
}

@media only screen and (max-width: 500px) {
    .feedback-main-container {
        .feedback-form {
            width: 100%;
            height: 100%;
            border-radius: unset;
        }
    }
}
