
@import "src/stylesheets/abstracts/variables.scss";


.mini-verification-overlay {
    position: absolute;
    left: 0; top: 0;
    width: 100%; height: 100%;
    background: white;

    .mini-verification{
        width: 100%; height: 100%;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow-y: auto;

        .mini-title,
        .mini-heading{
            width: 90%;
            height: 10%;
            margin: auto;
            box-sizing: border-box;
            padding: 1rem 0;
        }

        .contents {
            height: 80%;
            width: 100%;

            &.loader{
                display: flex;
                justify-content: center;
                flex: 1;
            }

            .contents-body{
                width: 100%;
                height: 75%;
                min-height: 150px;
                // padding-top: 1rem;
                box-sizing: border-box;

                margin-bottom: 70px;

                &.signup{
                    min-height: 450px;

                    .input-boxes .phone.u-info{
                        margin: 1rem 0;
                    }
                }
                .input-body{
                    width: 80%;
                    margin: 0 auto;
                }
                

                .input-boxes,
                .verification-method,
                .error,
                .terms-check{
                    width: 80%;
                    margin: 1rem auto 2rem;

                    .phone.u-info{
                        margin-top: 1rem;
                    }

                    .checkbox{
                        min-width: 30px;
                        min-height: 30px;
                    }
                }

                .error{
                    color: $color-primary;
                    font-size: 1.2rem;
                    margin: 0 auto;
                }

                .terms-check{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.5rem;
                    a{
                        text-decoration: none;
                    }
                }
            }

            .input-body {
                width: 80%;
                margin: auto;
                text-align: center;

                .description {
                    padding: 2rem 0;
                }

                .error{
                    color: $color-primary;
                    font-size: 1.2rem;
                }
            }

            
        }
        .button-container-mini {
            width: 80%;
            height: 8%;
            min-height: 70px;
            margin: 0 auto 0;
            display: flex;
            justify-content: space-around;
            position: sticky;
            bottom: 0%;
            background-color: white;

            button{
                height: 3rem;
                width: 8.75em;
                padding-inline: 1.25rem;
            }
        }
    }
}

@media only screen and (max-height: $no-nav-height){
    .mini-verification-overlay {
        .mini-verification{
            .contents {
                .contents-body {
                    height: 75%;
                }
            }
        }
    }
}
