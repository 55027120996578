
@import "src/stylesheets/abstracts/variables.scss";


.auth{
    .form {
        .verify {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
            height: 32em;
            align-content: flex-start;
            padding-left: 1em;

            .form-header {
                width: 100%;
            }

            .description {
                font-size: 1rem;
            }

            .verification {
                .code {
                    input {
                        width: 40px;
                    }
                }
                
            }
        }
        .verification-btn-container{
            position: absolute;
            bottom: 1%;
            width: 100%;
            display:flex;
            justify-content: space-between;
        }
    }

    .terms-container {
        width: 75%;
        display: flex;
        padding-bottom: 1em;

        .label-container {
            color: $color-grey-77;
            font-size: 1rem;
            a{
                text-decoration: none;
            }
        }
    }

    .btn-container {
        align-items: flex-start;
        .create {
            width: 8.75em;
            height: 2.5em;
        }

        .link{
            text-decoration: none;
        }
    }

    .version {
        position: absolute;
        bottom: 0;
        padding: 1em;
    }
}

@media only screen and (max-width: $mobile-width){
    .auth {
        .version {
            padding: unset;
            padding-bottom: .2rem;
        }
    }
}
