@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

$font-family-base: "Jost", sans-serif;

/**
 * Font Weights
 */
$font-weight-thin         : 100;
$font-weight-extra-light  : 200;
$font-weight-light        : 300;
$font-weight-regular      : 400;
$font-weight-medium       : 500;
$font-weight-semi-bold    : 600;
$font-weight-bold         : 700;
$font-weight-extra-bold   : 800;
$font-weight-black        : 900;

/**
 * Text default styles
 */

.default-text{
    font-family: $font-family-base;
    font-size: 1rem;
    font-weight: $font-weight-regular;
    color: $color-opt-black;
}

.header-1 {
    @extend .default-text;
    font-size: 2.125rem; //33px
    font-weight: $font-weight-medium;
}
.header-2 {
    @extend .default-text;
    font-size: 3rem; //48px
    color: $color-opt-black;
}

.header-3 {
    @extend .default-text;
    font-size: 2.2rem; //36px
    color: $color-opt-black;
    font-weight: $font-weight-medium;
}
.header-3-red {
    @extend .default-text;
    font-size: 1.5rem;
    color: $color-primary;
    font-weight: $font-weight-medium;
}
.header-4 {
    @extend .default-text;
    font-size: 1.5rem; //24px
    font-weight: bold;
    color: $color-white;
}
.header-5 {
    @extend .default-text;
    font-size: 1.25rem; //24px
    color: $color-white;
}

.subtitle {
    font-weight: $font-weight-regular;
    font-size: 1.2rem;
}

.description {
    @extend .default-text;
    font-size: 1.5rem; //24px
    color: $color-opt-black;
    font-weight: $font-weight-regular
}

.opt-text-small {
    @extend .default-text;
    font-size: 0.875rem; //14px
    font-weight: $font-weight-regular;
}

// Recommended paragraph line length is 45-75 characters
// ( approximately 34em || ~544px)