
@import "src/stylesheets/abstracts/variables.scss";


.mini-auth-container {
    height: 100%;
    position: relative;
    .slide{
        .slide-content{
            .main-content{
                height: 85%;
                padding-top:15%;
                &.full-content{
                    padding-top: 0;
                }
                .input-body{
                    .description{
                        font-size: 16px;
                    }
                }
     
                .input-boxes{

                    &.verification{
                        width: 100%;
                        .phone{
                            margin-bottom: 1rem;
                        }
                    }
                    .dialogue{
                        text-align: center;
                    }
                    .text{
                        height: 54px;
                        margin: 1rem 0 0
                    }
                    .phone{
                        margin: 1rem 0 0
                    }
                    //here
                    &.signup{
                        .text, .phone{
                            margin: 1.5rem 0 0
                        }
                    }
                }
                .verification-method{
                   .swapVerification{
                        font-size: 16px;
                   }
                }

                .verification-method{
                    display: flex;
                    justify-content: center;
                    .why-link{
                        margin-top:1rem;
                        text-decoration: none;
                    }
                    a.change-method{
                        color: $color-primary;
                    }
                }

                .terms-check{
                    margin: 1rem 0;
                    .checkbox{
                        margin: 0;
                    }
                    display: inline-flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.5rem;
                    a{
                        text-decoration: none;
                    }
                }

                .error{
                    font-size: 12px;
                    margin-bottom: 1rem;
                    color: red;
                }

                .radioset{
                    margin: 1rem auto;
                    text-align: center;

                    .radioset-options{
                        justify-content: center;
                    }
                }

                .input-body{
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    text-align: center;

                    .description{
                        width: 80%;
                        font-size: 1rem;
                        margin: 1rem 0;
                    }
                    a{
                        font-size: 1.5rem;
                        text-decoration: none;
                    }
                }
            }

            .opt-assurance{
                height: 15%;

                text-align: center;

                .description{
                    font-size: 1rem;
                }

                .link{
                    text-decoration: none;
                    display: flex;
                    justify-content: center;
                    gap: 0.5rem;
                }
            }
        }
    }

    // .loader-container{
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     z-index: 5;
    //     background-color: rgba(128, 128, 128, 0.103);
    //     border-radius: 2rem;
    // }

    .slide {
        &.v-enter-active, &.v-leave-active {
            transition: transform 0.25s ease, opacity 0.25s ease;
            position: relative;
            .slide{
                position: absolute;
            }
        }
        &.v-enter-from {
            transform: translateX(100%);
            opacity: 0;
        }
        &.v-leave-to {
            transform: translateX(-100%);
            opacity: 0;
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    .mini-auth-container {
        .loader-container {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0;
            left: 0;
        }
        .slide {
            .slide-content {
                .main-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 0 1.5rem;
                    box-sizing: border-box;

                    .error,
                    .description,
                    .hint {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        text-align: center;
                        padding-top: 1rem;
                        box-sizing: border-box;
                    }                    
                }
                .opt-assurance {
                    padding: 0 1rem;
                }
            }
            .button-container {
                // height: 20% !important
            }
        }
    }
}

