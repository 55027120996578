
@import "src/stylesheets/abstracts/variables.scss";


.pause-meeting-container{

    .popup-content{
        .subTitle{
            text-align: center;
            width: 90%;
            font-size: 1.3rem;
        }
    }
    
}
