
@import "src/stylesheets/abstracts/variables.scss";


.profile-qr-view {
    height: 100%;
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .profile-container{
        margin-top: 40px;
        .profile-icon-container {
            .profile-pic,
                .profile-initials,
                .icon{
                    height: 6rem;
                    width:  6rem;
                    border-radius: 100%;

                    object-fit: cover;
                }
                .profile-initials{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    font-size: 1.4rem;
                    background-color: $color-grey-D5;
                    color: white;
                }
        }
        .profile-name-container {
            // background:green;
            font-size: 1.5rem;
        }

        @media screen and (min-height: 700px) {
            .profile-name-container{
                font-size: 2rem;
                margin: 0.5rem;
            }
        }
    }

    .profile-qr{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 2;
        padding-bottom: 25%;
        font-size: 1.5rem;
        font-weight: bold;
        color: $color-primary;
        .qr-code{
            width: 300px;
            height: 300px;
            background-color: $color-opt-black;
            border-radius: 2rem;
        }
    }

    @media screen and (max-height: 700px) {
        .exit-btn button.borderless {
            padding: unset;
            height: unset;
        }
        .exit-btn {
            padding-bottom: unset;
        }
    }
    
}
@media only screen and (max-width: $mobile-width){
    .profile-qr-view {
        .exit-btn {
            padding-bottom: 2rem;
        }
    }
}

