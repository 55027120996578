
@import "src/stylesheets/abstracts/variables.scss";


.custom-text-area {
    position: relative;
    margin: 2rem 0 1rem;
    // margin: unset;
    .input {
        box-sizing: border-box;
        padding: 10px;
        width: 100%;
        border: none;
        border-radius: 5px;
        font: inherit;
        color: $color-grey-77;
        font-size: 1rem;
        background-color: transparent;
        outline: 1px solid $color-grey-77;
        resize: none;


        &.noBorder {
            border: none;
            outline: none;
            padding-left: 8%;
        }

        &:disabled {
            color: $color-opt-black;
            font-size: 1.25rem;
        }
    }


    .input-label {
        pointer-events: none;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 1.3rem;
        transform: translate(10px, 10px);
        transition: transform .25s;
    }

    .input::placeholder {
        color: black;
    }

    .input:focus+.input-label,
    .input:valid+.input-label {
        transform: translate(10px, -1rem) scale(.8); //Posistion of the label when selected
        color: $color-primary; //Color of the label text when selected
        padding-inline: 5px;
        background-color: #fff; // **IMPORTANT** Change this to match the color of the document. (label background to hide border)
        font-size: 1.2rem;
    }

    .input:is(:focus, :valid) {
        outline-color: $color-primary; //color of the input field border when selected
    }

    .input:disabled {
        opacity: .5;
    }

    .input:disabled+.input-label {
        opacity: .7;
    }
    .addFocus+.input-label{
        transform: translate(10px, -1rem) scale(.8) !important; //Posistion of the label when selected
        color: $color-primary; //Color of the label text when selected
        padding-inline: 5px;
        background-color: #fff; // **IMPORTANT** Change this to match the color of the document. (label background to hide border)
        font-size: 1.2rem;
    }
    .addFocus{
        outline-color: $color-primary;
    }

    // Alternate Code where inputs are no longer red when unselected
    // .input:focus+.input-label,
    // .input:not(:placeholder-shown)+.input-label {
    //     transform: translate(10px, -1em) scale(.8); //Posistion of the label when selected
    //     padding-inline: 5px;
    //     background-color: #fff; // **IMPORTANT** Change this to match the color of the document. (label background to hide border)
    //     font-size: 1.5rem;
    // }
    // .input:focus+.input-label,
    // .input:not(:placeholder-shown):invalid+.input-label{
    //     color: $color-primary; //Color of the label text when selected
    // }
    // .input:focus,
    // .input:not(:placeholder-shown):invalid {
    //     outline-color: $color-primary; //color of the input field border when selected
    // }
}
