
@import "src/stylesheets/abstracts/variables.scss";


.paid-icon {
    aspect-ratio: 1 / 1;
    position: relative;
    width: 50%;

    &.i-pay-you {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: calc(50% - 1px);
            background-color: $color-opt-red;
        }
    }

    &.you-pay-me {
        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 2px;
            top: calc(50% - 1px);
            background-color: $color-opt-black;
        }
        &::after {
            content: '';
            position: absolute;
            width: 2px;
            height: 100%;
            left: calc(50% - 1px);
            background-color: $color-opt-black;
        }

    }
}
