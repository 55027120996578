
@import "src/stylesheets/abstracts/variables.scss";


.qr-view-container{
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1rem;

    .title{
        font-size: 2em;
        font-weight: 400;
        color: $color-opt-black;
        text-align: center;
    }
    .description{
        margin: auto;
        text-align: center;
        line-height: 18pt;
        font-size: 1em;
        padding-top: 2.5%;
        color: $color-opt-black;
        font-weight: 400;

        .link{
            color: $color-blue-1;
        }
    }
    .qr-container{
        margin: auto;
        margin-top: 3%;
        height: 250px;
        width: 250px;
        background: $color-opt-black;
        margin-bottom: 1%;
    }
    .qr-description{
        text-align: center;
        margin: 0.5rem auto;
        margin-bottom: 4rem;
        width:60%;
        font-size: 1.5em;
        color: $color-opt-black;
        line-height: 22pt;
        font-weight: 300;
    }
    .button-section{
        margin:auto;
        width: 50%;


        .button-container{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .continue{
                padding: 1rem 5rem;
                font-size: 1.25rem;
                font-weight: 300;
                width: fit-content;
            }
            .back{
                font-size: 1.25rem;
                color: $color-primary;
            }
        }
    }
}
@media only screen and (max-width: 770px)
{
    .qr-view-container {
        .button-section {
            .button-container {
                .back {
                    margin-right: 1em;
                }
            }
        }
    }
}
