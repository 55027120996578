
@import "src/stylesheets/abstracts/variables.scss";


.meeting-note-container {
    .note-container {
        width: 100%;
        height: 55%;
        box-sizing: border-box;

        .invite-note {
            min-height: 15%;
            font-size: 1.3rem;
            width:80%;
        }
    }
}

@media only screen and (max-height: 800px){
    .meeting-note-container {
        .content-container {
            .note-container {
                margin-bottom: unset;
                padding: 0 1rem;
                .custom-input{
                    text-area{
                        overflow-y: auto;
                        max-height: 38vh;
                    }
                }
            }            
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .meeting-note-container {
        .content-container {
            .message-container {
                min-height: unset;
                height: unset;
            }
            .note-container {
                height: unset;
                margin-bottom: unset;
                padding: 0 1rem;

                .invite-note {
                    min-height: 9%;
                }
            }            
        }
    }
}

