.center {
    display: flex;
    align-items: center;
}

.flex-center {
    display: flex;              //F
    flex-direction: column;     //C
    align-items: center;        //C
    justify-content: center;    //C
}

//This is the red line used to divide content in opt
.red-divider {
    width: 28rem;
    background-color: $color-red-1;
    height: 6px;
    border-radius: 13%;
}

//Multiple buttons centered with some padding in between them
.btn-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-bottom: 1rem;

    button {
        padding: 0.7rem;
        margin-bottom: 0.5rem;
    }
}