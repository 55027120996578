.auth, .new-auth{
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-height: 47rem), (max-width: 48rem) {
        justify-content: unset;
    }
    @media (max-height: 47rem), (max-width: 48rem) {
        &, .auth-container{
            top: 0;
        }
    }

    .auth-loader{
        position: absolute;
        z-index: 3;
        width: 100%;
        height: 100%;
        background-color: rgba($color-opt-grey, 0.5);
        border-radius: 1rem;
    }
    .auth-container {
        position: relative;
        padding: 2.5rem; //40px
        display: grid;
        gap: 2rem;
        &.error{
            border-color: $color-primary;
        }
        @media (max-height: 47rem), (max-width: 48rem) {
            min-width: unset;
            width: 100vw;
            height: 100%;
            max-height: 100%;
            border-radius: 0px;
            display: flex;
            justify-content: center;
            padding: unset;
        }
        @media screen and (min-height: 47rem) and (min-width: 48rem) {
            max-height: 47rem; // 752px;
            max-width: 48rem;  // 768px;
            width: 48rem;
            border-radius: 3rem;
            grid-template-columns: 17rem 1fr;
            padding: unset;
        }
        .skyscraper,
        .form,
        .skyscraper .image{
            height: 100%;
            box-sizing: border-box;
            position: relative;
        }
        .skyscraper {
            @media (max-height: 47rem), (max-width: 48rem) {
                display: none;
            }
            @media screen and (min-height: 47rem) and (min-width: 48rem) {
                width: 17rem;
                background-color: $color-opt-black;
                border-radius: 0rem;
                border-top-left-radius: 3rem;
                border-bottom-left-radius: 3rem;
                overflow: hidden;
                img.image,svg.icon, div.meeting7{
                    position: absolute;
                    display: inline;
                    top: 0;
                    left: 0;
                }
                img.image{
                    width: auto;
                    height: 100%;
                    background-size: cover;
                    object-fit: contain;
                    &.login, &.signup{
                        left: -18.5rem;
                    }
                    &.verification{
                        left: -18.5rem;
                    }
                }
                svg.icon{
                    left: 1rem;
                    top: 1.25rem;
                    width: 6.25rem;
                    height: auto;
                    .path, .fill, .filled{
                        fill: $color-white;
                    }
                }
            }
        }
        .form{
            display: grid;
            place-content: center;
            place-items: baseline;
            gap: 0.5rem;
            grid-template-rows: auto dense;
            min-height: 580px;
            &, .custom-input, .input{
                width: 23.25rem;
                box-sizing: border-box;

                @media (max-height: 47rem), (max-width: 48rem) {
                    width: 20rem;
                    justify-items: center;
                    max-height: 100vh;
                    .radioset,.radio-container,.btn-container{
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        justify-content: center;
                        width: 100%;
                        gap: 0.25rem;
                    }
                    .radioset,.radio-container{
                        .radioset-label{
                            margin-right: unset;
                        }
                        .radioset-label, .radioset-options,  .radioset-option{
                            width: max-content;
                            flex-wrap: nowrap;
                            margin-inline: unset;
                        }
                        .radioset-label, .radioset-options{
                            gap: 0.5rem;
                        }
                    }
                    .terms-container{
                        width: 98%;
                    }
                    .btn-container{
                        flex-direction: column;
                    }
                }
            }
            .header-1{
                margin-block: unset;
            }
            .custom-input{
                margin: 0.5rem 0 0rem;
                padding-bottom: 0.4rem;
            }
            .phone-input-container{
                margin: 0.5rem 0 0.4rem;
            }

            .change-method{
                color: $color-primary;
                margin-bottom: 0.5rem;
            }

            .link.why-link{
                text-decoration: none;
            }

            .error-msg{
                color: $color-primary;
            }
            .radioset-label,
            .radioset-option{
                @extend .opt-text-small;
                margin: 0 1rem 0.5rem 0;
            }
            .checkbox{
                height: 22px;
                width:  22px;
                margin: 0rem 1em -0.5em 0;
            }
            .btn-container{
                padding-bottom: unset;
                button{
                    min-width: max-content;
                }
            }
        }
    }
}