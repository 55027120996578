.new-invite-container,
.new-invite-container > .page {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.new-invite-container{
    height:100%;
    position: relative;
}
.new-invite-container > .page {
    &.v-enter-active, &.v-leave-active {
        transition: transform 0.25s ease, opacity .25s ease;
    }
    &.v-enter-from {
        transform: translateX(100%);
        opacity: 0;
    }
    &.v-leave-to {
        transform: translateX(-100%);
        opacity: 0;
    }
}
.new-invite-container .meeting-type-container .button-container .buttons{
    button{
        width: 14.5rem;
    }
}


// .meeting-type-container {
//     height: 100%;
//     min-height: 620px;
//     padding: 0 3rem;
//     // display: flex;
//     // flex-direction: column;
//     // justify-content: space-between;

//     .title-container {
//         width: 100%;
//         min-height: 12.5%;
//         display: flex;
//         justify-content: center;
//         text-align: center;
//         position: relative;
//         box-sizing: border-box;
//         // margin-top: 4.5rem;

//         .title {
//             color: $color-opt-black;
//             margin: auto;
//             padding-top: 1rem;
//             font-size: 2.5rem;
//         }
//     }

//     .message-container {
//         padding: 0 2rem;
//         font-weight: 400;
//         font-size: 1.4rem;
//         text-align: center;
//         color: $color-opt-black;

//     }

//     .type-button-container {
//         display: flex;
//         flex-direction: column;
//         align-items: center;
//         color: $color-primary;
//         font-size: 1.5rem;

//         .buttons {
//             display: flex;
//             flex-direction: column;
//         }
//         .action-title {
//             font-size: 2rem;
//             margin-bottom: 1rem;
//         }
//         .black-outline {
//             @extend .outline;
//             // border: solid 2px $color-grey-3;
//             margin-bottom: 1rem;
//             // font-size: 1.25rem;
//             // padding: 1.2rem;
//             height: unset;
//             // width: unset;
//             white-space: nowrap;
//         }
//         .prevPage {
//             width: 70%;
//             height: unset;
//             padding: 2.5% 0;
//             text-align: center;
//             font-size: 1.25rem;
//             color: $color-blue-2;
//             text-decoration: none;

//             &:focus {
//                 color: white;
//             }
//         }
//     }

//     .button-container{
//         width: 70%;
//         height: 20%;
//         margin: auto auto 0 auto;

//         display:flex;
//         flex-direction: column;
//         justify-content: center;
//         align-items: center;
//         gap: 1rem;

//         box-sizing: border-box;
//     }
// }

// @media only screen and (max-width: 500px){
//     .meeting-type-container{
//         padding: unset;

//         .title-container {
//             margin-top: 2rem;
//         }

//         .nav-button-container {
//             padding-bottom: 0.5rem;
//         }
//     }
// }

.meeting-container{
    place-content: space-between;
    display: flex;
    flex-direction: column;
    .button-container{
        margin: auto auto 0 auto;
    }
}

.meeting-container{
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;

    .title-container{
        width: 100%;
        min-height: 115px;
        display: flex;
        justify-content: center;
        text-align: center;
        position: relative;
        box-sizing: border-box;
        
        .flow-title{
            color: $color-opt-black;
            font-size: 1.25rem;
            position: absolute;
            bottom: 2.5rem;
            margin-bottom: 1em;
        }

        .title{
            color: $color-opt-black;
            font-size: 2.5rem;
            padding-top: 1rem;
            position: absolute;
            bottom: 0;
        }
    }
    .content-container{
        .message-container{
            min-height:10%;
            font-size: 1rem;
            text-align: center;
            margin: auto;
            width:85%;
            color: $color-opt-black;
        }
    }

    .button-container{
        width: 80%;
        height: 10%;
        margin: auto auto 0 auto;
        display:flex;
        box-sizing: border-box;
        justify-content: space-between;
        .previous,
        .confirm-details{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .confirm-details:disabled{
            color: $color-grey-5;
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .meeting-container{
    
        .title-container{
            min-height: 70px;
            
            .flow-title{
                margin-bottom: 0;
            }
    
            .title{
                padding-top: 0;
            }
        }
    }
}


// .page .meeting-container, .page .meeting-type-container{
//     @media only screen and (max-height: 1000px){
//         // max-height: calc(100% - 70px);
//     }
// }
// .page .meeting-container, .page .meeting-type-container{
//     @media only screen and (max-height: 800px){
//         place-content: space-between;
//         display: flex;
//         flex-direction: column;
//         padding-bottom: 2rem;
//         min-height: unset;
//         .title-container{
//             min-height: 4rem;
//             margin-top: unset;
//             .flow-title{
//                 font-size: .75rem;
//                 bottom: 1.5rem;
//             }
//             .title{
//                 font-size: 1.5rem;
//             }
//         }
//         .button-container{
//             &, .buttons, button {
//                 font-size: 1.25rem;
//             }
//             .black-outline{
//                 margin-bottom: 0.5em;
//             }
//         }
//     }
//     @media only screen and (max-height: 710px){
//         max-height: calc(100vh - 70px);
//         .title-container{
//             min-height: 3rem;

//             .flow-title{
//                 bottom: 2em;
//                 font-size: 1em;
//             }

//             .title{
//                 font-size: 2em;
//             }
//         }
//         .message-container{
//             padding-block: 0 0.25em;
//             box-sizing: border-box;
//             font-size: 1rem;
//         }
//      }
// }