
@import "src/stylesheets/abstracts/variables.scss";


.payment-method{
    width: 80%;
    background-color: $color-grey-1;
    border-radius: 1rem;

    display: flex;
    flex-direction: column;
    
    cursor: pointer;

    &:hover{
        background-color: $color-grey-2
    }
    .payment-body{
        padding: 1rem;
        width: calc(100% - 2rem);
        display: flex;
        gap: 5%;

        .payment-icon{
            width: 4rem;
            height: 4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                width: 3rem;
                height: 3rem;
            }
        }

        .payment-description{
            text-align: left;
            width: calc(90% - 4rem);
            .payment-text{
                font-size: 1.2rem;
                font-weight: bold;
            }

            .add-payment{
                font-size: 1.5rem;
                font-weight: bold;
            }
        }

        .payment-actions{
            width: 10%;
            display: flex;
            justify-content: center;
            align-items: center;
            svg{
                width: 2rem;
                height: 2rem;
                transform: rotate(90deg);
            }
        }
    }
    .payment-buttons{
        padding: 0 1rem;
        display: flex;
        justify-content: flex-end;
        width: calc(100% - 2rem);
        gap: 0.5rem;

        .edit, .delete {
            cursor: pointer;
            svg{
                height: 24px;
                width: 24px;
                fill: $color-grey-4;
            }
        }
    }
}

