
@import "src/stylesheets/abstracts/variables.scss";


.header-1, .subtitle {
    text-align:center;
    padding: 1em 2em 0 2em;
}
.payment-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 30%;
}
p {
    position: absolute;
    bottom: 10%;  
    left: 50%; 
    transform: translate(-50%, -50%);
    router-link, a {
        color: $color-red-1;
        text-decoration: none;
    }
}
.primary {
    position: absolute;
    bottom: 2%;  
    left: 50%; 
    transform: translate(-50%, -50%);
}

