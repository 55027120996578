$skele-gradient: linear-gradient(0.0turn, rgba($color-opt-grey, 0), rgba($color-opt-grey, 0.5), rgba($color-opt-grey, 0));



@keyframes loading_slide {
    0%{
        transform: translateY(-100%);
    }
    50%{
        transform: translateY(0%);
    }
    100%{
        transform: translateY(100%);
    }
}

.skeleton{
    background-color: $color-opt-black;
    background: $skele-gradient;
    background-blend-mode: screen;    
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    overflow: hidden;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: loading_slide;
}
.skeleton-img,
.skeleton-loader{
    @extend .skeleton;
}