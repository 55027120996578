
@import "src/stylesheets/abstracts/variables.scss";


.radioset{
    width: 100%;
    height: 100%;

    .radioset-label{
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    .radioset-options{

        &.row{
            display: flex;
            flex-wrap: wrap;
            gap: 0.25rem;
        }

        .radioset-option{
            display: flex;
            margin: 0.5em;
            align-items: center;
            .label{
                padding-left: 0.5rem;
                
            }
        }
    }

    .radioset-label,
    .radioset-options .radioset-option .lable{
        font-size: 14px;
        color: #777777FF
    }

}
