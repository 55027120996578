
@import "src/stylesheets/abstracts/variables.scss";


.calendar {
    .calendar-header {
        display: flex;
        justify-content: center;
        align-items:center;
        margin-bottom:5%;
        text-align: center;
        font-weight: 500;
        color: $color-opt-black;
        
        .title{
            width:15%;
        }
        .left-chevy,
        .right-chevy{
            display: flex;
            justify-content: center;
            transform: scale(.65);
            align-items: center;
        }
        .right-chevy{
            margin-left: 15%;
        }
        .left-chevy{
            margin-right: 15%;
        }
    }
    .dates-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .day{
            padding:5%;
            text-align: center;
            font-weight: 400;
            color: $color-opt-black;
        }
        .date {
            grid-column: var(--column);
            padding: 10%;
            text-align: center;
            color: $color-red-2;
            font-weight:500;
            border-radius: 10px;
            margin: 5%;

            &.selected {
                background: $color-red-2;
                font-weight: 500;
                color: white;
                font-weight: 500;
            }
            &:hover {
                background: $color-red-2;
                color: white;
                font-weight: 500;
                cursor: pointer;
            }
            &.disabled{
                font-weight: 500;
                color: $color-grey-BF;
                pointer-events: none;    
            }
            &.currentDay{
                font-weight:700;
                text-decoration: underline 2px solid;
            }
        }
        .day{
            font-size: .8rem;
        }
    }
}
