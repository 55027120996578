
@import "src/stylesheets/abstracts/variables.scss";



.popup-video{
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    
    .video-frame{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 50%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        .video{
            width: 100%;
            height: 100%;
            min-height: 380px;
            max-width: 870px;
            
            border-radius: 50px;
            background: rgb(171, 170, 170);
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    .popup-video{
        .video-frame{
            width: 80%;
            height: 70%;
        }
    }
}
