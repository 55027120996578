
@import "src/stylesheets/abstracts/variables.scss";


.note-to-sender-container {
    .slide-header-note {
        height: 5%;
        font-size: 2rem;
        text-align: center;
        margin-bottom:2%;
      
    }
    .slide-content-note{
        height: 85%;
        padding: 0 1.2rem;
        box-sizing: border-box;

        .receiver-information {
            // height: 70%;
            max-height: 50%;
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            .sender-note,
            .sender-location {
                width: 100%;
                padding: 1rem .5rem;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;

                .description{
                    font-size: 1.2rem;
                }
                &__heading {
                    font-size: 1.2rem;
                    font-weight: bold;
                }
                &__note {
                    font-size: 1.1rem;
                }
            }
        }
        .sender-response {
            margin-top:10%;
            width: 100%;
            height: 30%;
        
            &__text{
                margin:unset;
                margin-top:4%;
            }
        }
    }
}
@media only screen and (max-width: $mobile-width) {
    .note-to-sender-container {
        padding: .5rem;
        box-sizing: border-box;
        height: unset;
        .slide-content-note {
            height:unset;
            .receiver-information {
                max-height: unset;

                .sender-location {
                    &__heading {
                        font-size: 1.1rem;
                        font-weight: bold;
                    }
                    &__note {
                        font-size: 1.1rem;
                    }
                }
            }

            .sender-response{
                height: unset;

                .custom-text-area{
                    textarea{
                        height: 150px;
                    }
                }
            }
        }

        
    }
}

