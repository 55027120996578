
@import "src/stylesheets/abstracts/variables.scss";


//Size of the loading icon
.loader-wrapper {
    min-height: 160px;
    .loader {
        width: 80px;
        height: 80px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
        margin-top: auto;
    }
    // .loader {
    //     width: 80px;
    //     height: 80px;
    //     min-height: 80px;
    //     box-sizing: border-box;
    //     border: 10px solid $color-opt-red;
    //     border-radius: 50%;
    //     position: relative;
    //     align-items: center;
    //     margin-left: auto;
    //     margin-right: auto;
    //     margin-top: auto;

    //     &::before, &::after {
    //         content: '';
    //         display: block;
    //         position: absolute;
    //         background: $color-opt-red;
    //         width: 50%;
    //         height: 8px;
    //         border-radius: 1rem;
    //         transform-origin: 4px 4px;
    //         transform: translateY(-50%) translateX(-4px);
    //         top: 50%;
    //         left: 50%;
    //     }
    //     &::before {
    //         animation: rotate 10s linear infinite;
    //     }
    //     &::after {
    //         animation: rotate 1.5s linear infinite;
    //     }

    //     @keyframes rotate {
    //         0%{
    //             transform: translateY(-50%) translateX(-4px) rotate(-60deg);
    //         }
    //         100%{
    //             transform: translateY(-50%) translateX(-4px) rotate(300deg);
    //         }
    //     }
    // }
    .loader-text {
        width: 100%;
        max-width:500px;
        text-align: center;
    }
    
    .loading-tip-container {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
    }
    section {
        height: 50%;
        display: flex;
        flex-direction: column;
    }

    .loading-move,
    .loading-enter-active,
    .loading-leave-active {
        transition: all 0.5s ease;
    }
    .loading-enter-from {
        opacity: 0;
        transform: translateX(40px);
    }
    .loading-leave-to {
        opacity: 0;
        transform: translateX(-40px);
    }
    .loading-leave-active {
        position: absolute;
    }
}

.loader-wrapper.full-screen {
    position:fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    background:rgba(255,255,255,0.8);
}
// .loader {
//     box-sizing: border-box;
//     width: 100%;
//     height: 100%;
//     border: 10px solid #162534; //color of the round rings
//     border-top-color: #4bc8eb; //outer ring 1st color
//     border-bottom-color: #f13a8f; //outer ring 2nd color
//     border-radius: 50%;
//     animation: rotate 5s linear infinite;
// }
// .loader-inner {
//     border-top-color: #36f372; //inner ring 1st color
//     border-bottom-color: #b900b9; //inner ring 2nd color
//     animation-duration: 2.5s;
// }
// @keyframes rotate {
//     0%{
//         transform: scale(1) rotate(360deg);
//     }
//     50%{
//         transform: scale(.8) rotate(-360deg);
//     }
//     100%{
//         transform: scale(1) rotate(360deg);
//     }
// }   

