
@import "src/stylesheets/abstracts/variables.scss";



.callendar-setup{
    .header{
        font-size: 3.9em;
        font-weight: 300;
        color: $color-opt-black;
        padding-bottom: 1rem;
    }
    .sub-header{
        font-size: 1em;
        display: flex;
        flex-direction: column;
        align-items: center;
        &__text{
            font-size: 1.9em;
            font-weight: 500;
        }

        &__description{
            color: $color-grey-4;
            font-size: 1em;
            width: 70%;
            padding-bottom: 3em;
        }
    }
    .content-container{
        align-self: center;
    }
    .button-container{
        font-size: 1em;
        padding-bottom: 2rem;
        button.calendar-button{
            font-size: 2em;
            height: 2.25em;
            width: 10em;
            background-color: $color-white;
            border: 1px solid #D5D5D5;
            border-radius: 0.5em;
            display: flex;
            justify-content: center;
            align-items: center;
            &:hover{
                cursor: pointer;
            }
        }
        .linked-confirmation{
            padding: 1rem 0;
            font-weight: 400;
            color: $color-opt-black;
            font-size: 2em;
        }
        .button-subtext{
            font-weight: 400;
            color: $color-opt-black;
            font-size: .9em;
        }
    }
    .next{
        font-size: 1.25rem;
    }

    .trouble-connecting{
        font-size: 1.2rem;
        color: $color-link;
        margin-bottom: 0.5rem;
        cursor: pointer;
    }
}
@media only screen and (max-width: $mobile-width) {
    .button-container{
        img {
            width: 85%;
        }
    }
}

