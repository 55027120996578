
@import "src/stylesheets/abstracts/variables.scss";



.meeting-price-container{
    .content-container {
        .price-container{
            height: 70%;
            min-height:16%;
            width: 80%;
            margin: auto;

            .review-section{
                padding: 1rem 0;
                box-sizing: border-box;
            }

            .payment-button-container{
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .meeting-price-container {
        .content-container {
            // height: 600px;
            .price-container {
                height: 100%;
                width: 95%;
                box-sizing: border-box;
                padding: 0 1rem;

                .review-section {
                    height: unset;
                    max-height: 60%;
                    text-overflow: ellipsis;
                    overflow: auto;
                }
            }            
        }
    }
}
