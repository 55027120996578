
@import "src/stylesheets/abstracts/variables.scss";


.home {
    width: 100%;
    height: $card-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;

    &.no-scroll{
        overflow: hidden;
    }

    .header-3-red {
        text-align: center;
        width: 100%;
    }

    .dashboard{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;

        .current-balance{
            width: 100%;
            height: 20%;
            min-height: 160px;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .balance-container{
                flex-direction: column;

                &:hover{
                    cursor: pointer;
                }
                .balance-indicator{
                    padding-right:2.5%;
                    font-size: 2.5rem;
                    color: $color-opt-black;

                    .balance-total{
                        font-size: 3rem;
                    }
                    &.negative {
                        color: $color-red-1;
                    }
                }
            }

            .profile {
                display: flex;
                align-items: center;
                flex-direction: column;
                text-align: center;
                font-size: 1.25rem;

                &.proxy-user{
                    .profile-pic,.profile-initials,.icon{
                        border: 2px solid purple;
                    }
                }

                cursor: pointer;

                .profile-pic {
                    height: 5.5rem;
                    width: 5.5rem;
                    border-radius: 100%;
                    object-fit: cover;
                }

                .profile-initials {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    height: 5.5rem;
                    width: 5.5rem;
                    font-size: 1.4rem;
                    border-radius: 100%;
                    background-color: $color-grey-D5;
                    color: white;
                }

                .icon {
                    height: 5.5rem;
                    width: 5.5rem;
                }
                .user-name{
                    width: 100%;
                    text-align:center;

                    &.rightAlign{
                        text-align: right;
                    }
                }
            }
        }

        .meetings-bar{
            width: 100%;
            height: 10%;
            min-height: 100px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            background-color: $color-red-1;
            color: $color-white;

            .upcoming-meetings,
            .previous-meetings{
                width: 22%;
                flex-direction: row;
                gap: 0.6rem;

                cursor: pointer;
                .total-meetings{
                    font-size: 24px;
                    font-weight: bold;
                }
            }
            .all-meetings{
                .meetings-header {
                    color: $color-white;
                    font-size: 24px;
                    font-weight: bold;
                }
                .subtitle{
                    cursor: pointer;
                }
                .meetings-header {
                    cursor: pointer;
                }
            }
            .header-5 {
                font-size: 16px;
                font-weight: normal;
            }
        }

        .notifications{
            width: 100%;
            height: 62%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;

            .welcome{
                width: 80%;
                height: 50%;
                padding: 1rem;
                margin: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                text-align: center;
                border-radius: 1rem;
                .header, .description{
                    font-size: 28px;
                    color: $color-grey-77;
                }
            }
            .next-meeting{
                // background:green;
                width: 100%;
                min-height: 30%;
                padding-bottom: 2rem;
                box-sizing: border-box;

                .header{
                    margin: auto;
                    padding-top: 1rem;
                    box-sizing: border-box;
                    width: 80%;
                    text-align: center;
                    font-size: 27px;
                    color: $color-grey-77;
                }
                .view-all{
                    text-align: center;
                }

                .loader-container{
                    height: 95%;
                    width: 95%;
                    margin: 2.5%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 1rem;
                    background-color: $color-grey-1;
                }
            }
            .dashboard-notifications{
                width: 70%;

                .header{
                    text-align: center;
                    font-size: 1.7rem;
                    color: $color-grey-77;
                }
                .learn-more{
                    text-align: center;
                }
            }
        }
        .button-container-home {
            height: 8%;
            width: 100%;
            background-color: $color-white;
            margin-top: auto;
            position: sticky;
            bottom: clamp(1rem, 2vh, 5%);
        }
    }

    .v-enter-active,
    .v-leave-active {
        transition: transform .25s ease;
    }

    .v-enter-from {
        transform: translateY(100%);
    }

    .v-leave-to {
        transform: translateY(100%);
    }
}


@media only screen and (max-height: $no-nav-height) {
    .home {
        &, .dashboard{
            justify-content: space-between;
        }
        .dashboard {
            .current-balance {
                padding: 2% 0;
                top: 0;
                position: sticky;
                .balance-container {
                    padding-left: 2%;

                    .balance-indicator {
                        font-size: 3rem;
                        .balance-total {
                            font-size: 3rem;
                        }
                    }
                }
                .profile {
                    padding-right: 2%;
                }
            }
            .meetings-bar {
                min-height: 80px;
                position: sticky;
                top: 160px;
                .all-meetings {
                    .meetings-header {
                        font-size: 1.2rem;
                    }
                }
            }
            .notifications {
                overflow: auto;
                .welcome{
                    width: 100%;
                    margin: 0 auto;
                    padding-top: 2rem;
                    box-sizing: border-box;
                    .header {
                        font-size: 1.3rem;
                    }
                    .description {
                        font-size: 1.3rem;
                    }
                }
            }
            .button-container-home {
                bottom: 2rem;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .home {
        position: absolute;
        top: 0rem;
        height: calc(100% - 70px);

        .dashboard {
            // height: unset;
            // height: calc(100% - 70px);
            display: flex;
            flex-direction: column;
            justify-content: center;

            .current-balance {
                height: 15%;
                min-height: 140px;
                padding: 2% 0;
                .balance-container {
                    padding-left: 2%;

                    .balance-indicator {
                        font-size: 2.2rem;
                        .balance-total {
                            font-size: 2rem;
                        }
                    }
                }
                .profile {
                    padding-right: 2%;
                }
            }
            .meetings-bar {
                position: relative;
                top: unset;
            }
            .notifications {
                height: 67%;
                max-height: unset;
                overflow: auto;

                .next-meeting {
                    min-height: unset;
                }
                .dashboard-notifications {
                    width: 90%;

                    .header {
                        width: 100%;
                        font-size: 1.3rem;
                    }
                }
            }
            .button-container-home {
                margin-top: unset;
                position: relative;
                bottom: unset;
            }
        }
    }
}
