
@import "src/stylesheets/abstracts/variables.scss";



.availability-overlay{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;

    &__header{
        height: 15%;
        font-size: 2.5em;
    }
    &__description {
        height: 20%;
        font-size: 1.5em;
        color: $color-opt-black;
    }

    &__input {
        width: 100%;
        height: 20%;

        .week-checkboxes{
            padding-bottom: 1.5rem;
            width: 100%;
            display: flex;
            justify-content: space-between;
            
            .day-option{
                display: flex;
                flex-direction: column;
            }
        }
    }
    &__confirm {
        height: 15%;
    }
    &__exit {
        position: sticky;
        bottom: 0%;
        height: 10%;

        .upload {
            width: 300px;
            height: 60px;
        }
    }

    .loader-container {
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: column;
        background-color: rgba(240, 240, 240, 0.5);
    }
}

@media only screen and (max-height: $no-nav-height){
    .availability-overlay {
        padding: .5rem 2rem;

        &__header {
            height: 10%;
            font-size: 2.3rem;
        }
        &__description {
            height: 20%;
            font-size: 1.5rem;
        }

        &__input {
            width: 100%;
            height: 25%;

            .week-checkboxes{
                padding-bottom: 1rem;
            }
        }
        &__confirm {
            height: 10%;
        }
        &__exit {
            height: 10%;
            padding: unset;
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .availability-overlay {
        padding: 1rem 2rem;
        &__header {
            height: 10%;
            font-size: 1.9rem;
        }
        &__description {
            height: 20%;
            font-size: 1.3rem;
        }

        &__input {
            width: 100%;

            .week-checkboxes{
                padding-bottom: 1rem;
            }
        }
        .week {
            display: flex;
            align-items: center;
            justify-content: center;
        }
        &__confirm {
            height: 10%;

            .overlay-back {
                width: 300px;
            }            
        }
        &__exit {
            height: 10%;
        }
    }
}

