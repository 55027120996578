
@import "src/stylesheets/abstracts/variables.scss";


.contact-details-container {
    .slide-content{
        margin-top:5%;
        .contact-warning {
            width: 100%;
            // height: 30%;
            text-align: center;
            &__section {
                height: 50%;
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }
        .contact-options{
            margin-top:5%;
            display:flex;
            flex-direction: column;
            justify-content: space-around;
            height:30%;
            font-size: 18px;
            color: $color-opt-black;
            .share-number,.share-email{
                display:flex;
                align-items: center;
                
                &-box{
                    box-sizing: border-box;
                    flex-grow:0;
                    height:35px;
                    width: 35px;
                    display:block;
                }
                &-text{
                    padding-left:4%;
                }
                .bold{
                    font-weight: bold;
                }
            }

        }
        .contact-response {
            width: 100%;
            // height: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            &__note {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }
}
@media only screen and (max-width: $mobile-width) {
    .contact-details-container {
        padding: 0 0.75rem;
        box-sizing: border-box;
        .slide-content {
            height: 80% !important;
            .contact-options{
                font-size: 12px;
                width: 95%;
                margin-left: auto;
                margin-right: auto;
            }
            .contact-response{
                height: unset;

                .custom-text-area{
                    width: 95%;
                    margin-left: auto;
                    margin-right: auto;
                    textarea{
                        height: 150px;
                    }
                }
            }

        }
    }
}

