
@import "src/stylesheets/abstracts/variables.scss";


 
.meeting-popup{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    background: $color-white;
    // justify-content: space-between;

    z-index: 1;

    button.close-btn{
        margin: 0.5rem 1rem 0px 0px;
    }

    .popup-content{
        width: 100%;
        max-height: 80%;
        overflow-y: auto;

        .info-header{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 1rem 0;
            padding: 0.5rem 0;

            &.retry-payment{
                color: $color-white;
                background-color: $color-primary;
            }
            

            .dialogue{
                font-size: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;

                span{
                    color: $color-primary;
                    font-weight: 600;
                }

                .icon{
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0.25rem;

                    svg{
                        width: 30px;
                        height: 30px;
                        path{
                            fill: $color-primary;
                        }
                    }
                }
                
            }
        }

        .text-block{
            $block-width: 60%;
            margin: 1rem 0;
            .header,
            .description{
                width: $block-width;
                margin: auto;
            }

            .header{
                font-weight: 600;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .description{
                font-size: 1rem;
                line-height: 1.5rem;
            }

            .icon-header{
                width: 100%;
                display: flex;

                .user-icon{
                    display: flex;
                    justify-content: center;
                    width: calc((100% - $block-width) / 2)
                }

                .user-header{
                    margin-bottom: 1rem;
                    .meeting-header{
                        font-size: 1.25rem;
                        line-height: 1.25rem;
                        margin-bottom: 0.25rem;
                    }
                    .user-name{
                        font-size: 2rem;
                        line-height: 2rem;
                        font-weight: 500;
                    }
                }
            }

            .reschedule-details{
                margin: auto;
                width: 80%;
                textarea{
                    cursor: default;
                }
            }
        }
    }

    .button-container{
        width: 70%;
        height: 20%;
        margin: auto auto 0 auto;

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        box-sizing: border-box;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .confirm-details:disabled{
            color: $color-grey-5;
        }

        .manage{
            position: relative;
            width: 100%;
        }

        .manage-popup{
            z-index: 1;
            position: absolute;
            bottom: 0;
            background-color: $color-white;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .close-button{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3rem;

                cursor: pointer;
            }
        }
    }

    .profile{
        background-color: $color-white;
        &, .profile-pic,
        .profile-initials,
        svg{
            height: 3.75rem;
            width:  3.75rem;
            border-radius: 50%;
            color: $color-white;
        }
        .profile-pic{
            object-fit: cover;
        }
        .profile-initials{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 2.25rem;
            background-color: rgba($color-primary, 0.67);
        }
        svg{
            background-color: $color-white;
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    .meeting-popup{
        .button-container{
            button{
                height: unset;
            }
        }
    }
}
