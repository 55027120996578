
@import "src/stylesheets/abstracts/variables.scss";


.qr-code-container {
    position: relative;

    .loading-container {
        position: absolute;
        left: 0; top: 0;
        width: 100%; height: 100%;
        display: grid;
        place-items: center;
    }
}
