
@import "src/stylesheets/abstracts/variables.scss";


$max-card-height: 1000px;

body{
    overflow: hidden;
}
#app > main {
    background-color: $color-background-solid;
    background-image: url('../resources/opt-background.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;

    .view-wrapper {
        display: flex;
        justify-content: center;
        
        flex-grow: 1;
        width: 100%;
        height: $view-height;
    }
    .page-container.card-base {
        overflow: hidden;
        position: relative;
        height: 100vh;
        width: 100vw;

        @media screen and (min-height: 800px) {
            top: 3.125rem; // 50px
        }
        width: 32.8125rem;
    }
    .feedback-container {
        width: 100px;
        height: 35px;
        position: absolute;
        bottom: 20px;
        right: 20px;
    }
}

@media only screen and (min-height: 1400px) {
    #app > main {
        .view-wrapper {
            align-items: center;
            .page-container.card-base {
                min-height: unset;
                max-height: $max-card-height;
                height: 1200px;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width) {
    :root {
        --doc-height: 100%;
    }
    html,
    body {
        padding: 0;
        margin: 0;
        height: 100vh; /* fallback for Js load*/
        height: var(--doc-height);
    }
    #app > main {
        position: relative;
        width: unset;
        min-height: unset;
        max-height: unset;
        height: unset;
        height: 100vh; /* fallback for Js load*/
        height: var(--doc-height);
        .view-wrapper {
            height: unset;
            
            .page-container.card-base {
                height: 100%;
                max-height: var(--doc-height);
                border-radius: 0;
                top: 0%;
            }
            .feedback-container {
                display: none;
            }
        }
    }
}

//Hide the Feedback button until they implement it in designs
@media only screen and (max-width: 770px) {
    #app > main {
        .view-wrapper {
            .feedback-container {
                display: none;
            }
        }
    }
}
