
@import "src/stylesheets/abstracts/variables.scss";


$active-color: #f3d23e;

.rating {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 22px;
    color: #a7a8a8;

    .list {
        margin: 5px 0;
        padding: 0;
        list-style-type: none;
        
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        &:hover {
            .star {
            color: $active-color;
            }
        }
        .star {
            svg{
                height: 80px;
                width: 80px;
            }
            display: inline-flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &:hover {
                &~.star {
                &:not(.active) {
                    color: inherit;
                }
                }
            }        
        }
    }
}

.active {
color: $active-color;
}


@media only screen and (max-width: $mobile-width){
    .rating{
        .list {
            margin: 5px 0;
            .star{
                svg{
                    height: 50px;
                    width: 50px;
                }
            }
        }
    }
}
