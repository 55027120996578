
@import "src/stylesheets/abstracts/variables.scss";


.time-scroller{
    height: 20rem;
    .more-indicator{
        height: 5%;
        .up-arrow{
            transform: rotate(180deg);
        }
    }
    .days-container{
        height:90%;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        // ??? Why did they want these gone?
        // box-shadow: 
        //     inset 0px 11px 8px -10px #CCC,
        //     inset 0px -11px 8px -10px #CCC;
        .next-prev-btn-container{
            width:100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .day{
            border-top: solid 4px $color-red-2;
            border-bottom: solid 4px $color-red-2;
            position: relative;

            .meeting-date{
                position: absolute;
                color: $color-primary;
                font-size: 1.25rem;
                padding-left: 2%;
                &.isHovered{
                    color: white;
                    z-index: 5;
                }
            }
    
            .meeting-times{
                width:100%;
                position: relative;
                height: 3.5rem;
                
                &:nth-child(even){
                    background:rgba(0, 0, 0, 0.03);
                }
                

                .meeting-time{
                    position: relative;
                    width:100%;
                    height: 100%;
                    .time{
                        height:100%;
                        position:absolute;
                        right:15%;
                        text-align:center;
                        top: 35%;
                    }
                    &.isHovered{
                        color: white;
                        cursor: pointer;
                        background: $color-red-2;
                    }
                }
                &:hover{
                    cursor: pointer;
                    background: $color-red-2;
                    color: white;
                }
            }
        }
    }
   
}
