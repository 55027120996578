
@import "src/stylesheets/abstracts/variables.scss";


.withdraw-form{
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    text-align: center;
    .balance-header {
        width: 100%;
        height: 12%;
        background-color: $color-primary;
        box-sizing: border-box;
        .header-title{
            color: $color-white;
            font-size: 1.5rem;
        }
    }
    .withdraw-page-body{
        height: 75%;
        color: $color-grey-4;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        .info-text{
            text-align: left;
        }
        .withdraw-input{
            text-align: left;
            width: 90%;
            .withdraw-input-container{
                font-size: 3rem;
                display: flex;
                gap: 0.5rem;
                .withdraw-amount-input{
                    font-size: 3rem;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid black;
                    outline: none;
                }
            }
        }
        .no-funds {
            text-align:center;
            display:block;
            width:100%;
        }
        .withdraw-available{
            text-align: left;
            width: 100%;
            .available-container{
                width: 90%;
                margin: auto;
                .amount{
                    font-size: 2rem;
                    color: $color-grey-2;
                }
            }
        }
        .withdraw-account-select{
            width: 100%;
            .withdraw-button-container{
                min-height:20%;
                width: 80%;
                margin: auto;
                .withdraw-button{
                    margin: 1rem 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.5rem 2rem;
                    width: 100%;
                    height: auto;
                    font-weight: bold;
                    &:focus{
                        background-color: $color-grey-1;
                    }
                    .withdraw-button-body{
                        // padding: 1rem;
                        width: calc(100% - 2rem);
                        display: flex;
                        gap: 5%;
                        height: 4rem;
                        text-align: left;
                        &.unselected{
                            height: 3rem;
                            align-items: center;
                        }
                        .withdraw-icon{
                            width: 4rem;
                            height: 4rem;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            svg{
                                width: 3rem;
                                height: 3rem;
                            }
                        }
                        .withdraw-description{
                            color: $color-grey-4;
                            width: calc(90% - 4rem);
                            .payment-info{
                                font-size: 1rem;
                                font-weight: normal;
                            }
                            .payment-text{
                                font-size: 1.2rem;
                                font-weight: bold;
                            }
                        }
                    }
                    .chevy{
                        transform: rotate(180deg);
                    }
                }
                .info-link{
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

}
.withdraw-form-btns{
    width: 85%;
    margin: auto;
    display:flex;
    justify-content: space-between;
    align-items: center;
    position:sticky;
    bottom: 0%;

    .previous{
        padding-bottom: 0;
    }

    .confirm-details {
        width: 10rem;
    }
}
@media only screen and (max-height: 975px) {
}
