
@import "src/stylesheets/abstracts/variables.scss";


body{
    overflow: hidden;

    #landing {
        $circle-width: clamp(2rem, 3vw,3vw);
        background-color: $color-white;
        &, .container{
            height: 100%;
            width:  100vw;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            text-align: center;
            flex-direction: column;
            overflow: hidden;
        }
        .container{
            box-sizing: border-box;
            padding: 7.25rem;
            z-index: 1;

            .title, .heading{
                color: $color-primary;
            }
            .title, .icon{
                height: 7.25rem;
                width: 15rem;
            }
            .heading {
                font-size: 4rem;
                padding: 1rem;
                width: 100%;
            }
            .subheading {
                font-size: 1.5rem;
                padding: 3rem;
                padding-top: 1rem;
            }
            @media only screen and (max-width: $no-nav-height){
                .title {
                    font-size: 4rem;
                }
                .heading {
                    font-size: 2rem;
                }
                .subheading {
                    font-size: 1.2rem;
                    padding: 1.5rem;
                }
            }
        }
        .circle-images {
            overflow: hidden;
            .circle {
                border-radius: 50%;
                background-color: white;
                border: 2px solid $color-opt-black;
                position: absolute;
                background-size: cover;
            }
            .meeting1 {
                height: calc( 6 * $circle-width);
                width: calc( 6 * $circle-width);
                bottom: calc(-3 * $circle-width);
                left: calc(2 * $circle-width);
                // background-image: url('resources/meetings/meeting1.jpg');
            }
            .meeting2 {
                height: calc( 10 * $circle-width);
                width: calc( 10 * $circle-width);
                bottom: calc(-3 * $circle-width);
                right: calc(-2 * $circle-width);
                // background-image: url('resources/meetings/meeting2.jpg');
            }
            .meeting3 {
                height: calc( 5.5 * $circle-width);
                width: calc( 5.5 * $circle-width);
                bottom: calc(-3 * $circle-width);
                right: calc( 12 * $circle-width);
                // background-image: url('resources/meetings/meeting3.jpg');
            }
            .meeting4 {
                height: calc( 4 * $circle-width);
                width: calc( 4 * $circle-width);
                bottom: calc(2 * $circle-width);
                left: calc(7 * $circle-width);
                // background-image: url('resources/meetings/meeting4.jpg');
            }
            .meeting5 {
                height: calc( 5 * $circle-width);
                width: calc( 5 * $circle-width);
                bottom: calc( 10 * $circle-width);
                left: calc( -1 * $circle-width);
                // background-image: url('resources/meetings/meeting5.jpg');
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    body {
        #landing {

            .circle-images {
                display: none;
            }
            .container {
                padding: unset;
                display: flex;
                justify-content: center;
            }
        }
    }
}
