
@import "src/stylesheets/abstracts/variables.scss";


.invite-link {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    gap: 5%;
    padding-bottom: 1.5rem;
    cursor: pointer;

    .toggle-container{
        display: flex;
        justify-content: center;
        align-items: center;
        background: lightgrey;
        height: 40px;
        width: 50px;
        border-top-right-radius: 5rem;
        border-bottom-right-radius: 5rem;
        padding: 1rem;

        font-size: 25px;
        color: black;
        font-weight: bold;

        svg{
            height: 50px;
            width: 50px;
            path{
                fill: black;
            }
        } 
    }

    .invite-body-container{
        // flex-grow: 1;
        width: 40%;
        .invite-name{
            font-weight: bold;
        }
        .invite-info{
            .time{
                font-weight: bold;
            }
        }
    }

    .invite-price-container{
        // flex-grow: 1;
        width: 15%;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        font-size: 1.5rem;

        .payment-icon{
            width: 3rem;
        }

        .payout{
            color: red;
        }
    }

    .invite-share-container{
        // flex-grow: 1;
        
        width: 15%;

        .share-icon-container{
            display: flex;
            align-items: center;
            
            svg{
                circle{
                    stroke: $color-primary;
                    fill: none;
                }

                path{
                    fill: $color-primary;
                }
            }

            &:hover{
                svg{
                    circle{
                        fill: $color-primary;
                    }
                    path{
                        fill: $color-white;
                    }
                }
            }
        }
    }

    &.inactive{
        .invite-body-container, .invite-price-container{
            opacity: 0.6;
        }
    }
}
