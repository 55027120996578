
@import "src/stylesheets/abstracts/variables.scss";


.signup-component-container { 
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    position: relative;
    
    .signup-input-container{ 
        width: 100%;
        display: flex;
        align-items: center;
        i{
            font-size: 22px;
            margin-top: 20px;
            font-weight: 600;
            color: greenyellow
        }
    }
    .fieldset{ 
        width: 100%;
        display: flex;
        align-items: center;
        .Password{
            margin: 0;
        }
    }
}

