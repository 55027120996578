
@import "src/stylesheets/abstracts/variables.scss";


.account-setup-container{
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    text-align: center;
    .balance-header {
        width: 100%;
        height: 10%;
        min-height: 10%;
        background-color: $color-primary;
        box-sizing: border-box;
        .header-title{
            color: $color-white;
            font-size: 1.5rem;
        }
    }
    .account-setup-body{
        height: 75%;
        color: $color-grey-4;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 80%;
        margin: auto;

        .header{
            min-height: 80px;
            font-size: 2.5rem;
            margin-top:5%;
            margin-bottom:2%;
        }
        .sub-text{
            min-height:100px;
        }
        .image{
            min-height:400px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

}
.account-setup-btns{
    width: 100%;
    height: 10%;
    background-color: white;
    position:sticky;
    display: flex;
    justify-content: space-around;
    bottom: 0%;
}

@media only screen and (max-height: $no-nav-height){
    .account-setup-container{
        .balance-header {
            height: 10%;
            min-height: unset;
        }
        .account-setup-body{
            height: 80%;
            width: 90%;

            .header{
                font-size: 2rem;
            }
            .sub-text{
                min-height:100px;
            }
            .image{
                min-height:200px;
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: $mobile-width){
    .account-setup-container{
        .balance-header {
            height: 10%;
            min-height: unset;
        }
        .account-setup-body{
            height: 80%;
            width: 90%;

            .header{
                font-size: 2rem;
            }
            .sub-text{
                min-height:100px;
            }
            .image{
                min-height:250px;
                width: 100%;
            }
        }
    }
}
