
@import "src/stylesheets/abstracts/variables.scss";


.rate-container{
    .blank {
        color: transparent;
    }
    width: 100%;
    height: 100%;
    font-size: 2.25rem;
    gap: 5%;
    color: $color-primary;
    .rate-title {
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }
    .input-pay-container{
        text-align: center;
        width: 100%;
        height: 60%;
        .rate,
        .minutes {
            width: 100%;
            height: 50%;
        }
        .amount{
            color: $color-opt-black;
            font-weight: bold;
        }
        .subText{
            font-size: 1.2rem;
            color: $color-opt-black;
        }
        input{
            font-size: 4rem;
            border: none;
            border-bottom: 2px solid $color-opt-black;
            margin-left: 2%;
            margin-right: 2%;
            text-align: center;
            font-weight: bold;
            color: $color-opt-black;
        }
        .paymentAmount{
            width: 25%;
        }
        .timeAmount{
            width: 25%;
        }
        .timeUnit{
            color: $color-opt-black;
            font-weight: bold;
        }
    }
    .enter-payment-information{
        width: 55%;
        padding: 2%;
        margin-top:10%;
        display:flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .earnings-container {
        height: 20%;
        width: 70%;
        padding: .5rem;
        margin: 10% auto 0 auto;
        background-color: $color-grey-E2;
        @media screen and (max-height: 800px) {
            margin: 0 auto;
        }
        .earnings-total {
            font-size: 1.5rem;
            color: $color-opt-black;
        }
        .earnings-info {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            font-size: 1.3rem;
            color: $color-opt-black;
        }
    }
    @media only screen and (max-width: $mobile-width){
        .enter-payment-information {
            width: 80%;
        }
    }
}
