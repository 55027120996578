
@import "src/stylesheets/abstracts/variables.scss";


.payment-select-window{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    background-color: rgba(121, 121, 121, 0.15);

    .payment-popup{
        z-index: 11;
        position: absolute;
        bottom: 1rem;
        left: 50%; 
        transform: translate( -50%, 0);

        padding: 2rem;
        width: 40rem;
        border-radius: 3rem;
        background-color: white;

        animation: slide .25s ease 1;

        @keyframes slide {
            0%{
                transform: translate(-50%, 50%);
                opacity: 0;
            }
            100%{
                transform: translate(-50%, 0%);
                opacity: 1;
            }
        }

        .popup-header{
            font-size: 2.5rem;
            text-align: center;
        }
        .popup-sub-header{
            font-size: 1.2rem;
            text-align: center;
        }

        .payment-method-container{
            margin: auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem 0;
            gap: 1rem;

            max-height: 24rem;
            overflow-y: auto;
            overflow-x: hidden;

            .default-payment-method{
                width: 80%;
                background-color: $color-grey-1;
                border-radius: 1rem;

                display: flex;
                flex-direction: column;
                
                cursor: pointer;

                &:hover{
                    background-color: $color-grey-2
                }
                .payment-body{
                    padding: 1rem;
                    width: calc(100% - 2rem);
                    display: flex;
                    gap: 5%;

                    .payment-icon{
                        width: 4rem;
                        height: 4rem;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        svg{
                            width: 3rem;
                            height: 3rem;
                        }

                        &.add-icon{
                            svg{
                                padding: 0.5rem;
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                    }

                    .payment-description{
                        text-align: left;
                        width: calc(90% - 4rem);
                        .payment-text{
                            font-size: 1.2rem;
                            font-weight: bold;
                        }

                        .add-payment{
                            font-size: 1.5rem;
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .payment-select-window {
        .payment-popup{
            width: 100%;
            bottom: 0;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;

            .payment-method-container {
                .default-payment-method {
                    .payment-body {
                        .payment-description {
                            .add-payment {
                                font-size: 1.1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}
