
@import "src/stylesheets/abstracts/variables.scss";


.payment-reconfirm-container{
    width: 100%;
    height: 100%;

    .title-container{
        display:flex;
        flex-direction: column;
        align-items: center;
        min-height: 15%;
        justify-content: center;
        // padding-top:5%;
        // padding-bottom:15%;
        .title{
            color: $color-opt-black;
            font-size: 2.5rem;
        }
        .sub-title{
            color: $color-opt-black;
            font-size: 1.25rem;
        }
    }

    .info-container{
        min-height: 40%;
        // background: teal;
        display:flex;
        flex-direction: column;
        justify-content: space-around;
        color: $color-opt-black;
        width: 80%;
        margin-left: 10%;

        &.increasedMargin{
            // margin-bottom: 76%;
        }
        
        .info{
            display:flex;
            flex-direction: column;
            justify-content:center;
            // margin-bottom:10%;

            &-title{

            }
            &-data{
                font-size: 1.5rem;
            }

        }
    }
    .payment-container{
        display:flex;
        flex-direction: column;
        min-height: 20%;
        color: $color-opt-black;
        .payment-btn{
            display:flex;
            align-items:center;
            justify-content:center;
            margin-top:2%;
            width:100%;
            font-weight:bold;
            padding: 7%;
            .chevy{
                transform: rotate(180deg);
                position: absolute;
                right:5%;
                svg path{
                    fill: $color-opt-black;
                }
            }
        }
        .more-info-btn{
            color: $color-opt-blue;
        }
    }

    .checkbox-container{
        height: 15%;
        width: 80%;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        .description-container{
            width: calc(100% - 37px);
        }
    }

    .spacer{
        min-height: 30%;
    }
    
    .button-container{
        display:flex;
        align-items:center;
        margin: auto;
        width: 80%;
        justify-content: center;
        padding-bottom:5%;
    }
    .loader-container{
        width: 100%;
        height: 100%;
        z-index: 5;
        background: white;
        position:absolute;
        .loading{
            position: absolute;
            left: 40%;
            top:35%;
        }
    }

}
@media screen and (max-height: 990px){
    .confirm-info-container{
        .info-container{
            min-height: 60%;
        }
        .payment-container{
            min-height: 30%;
        }
    }

}
@media screen and (max-height: 720px){
    .confirm-info-container{
        .info-container{
            min-height: 70%;
        }
        .payment-container{
            min-height: 35%;
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .confirm-info-container{
        .info-container{
            min-height: 60%;
        }
        .payment-container{
            min-height: 30%;
        }
    }
}

