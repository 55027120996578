
@import "src/stylesheets/abstracts/variables.scss";


.tester{
    margin: auto;
    height:50%;
    width: 50%;
}

