.detail-popup{
    position: absolute;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    background: white;

    .loader{
        z-index: 5;
    }

    .popup-content{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-height: 80%;
        overflow-y: auto;
    }

    button.close-btn{
        margin: 0.5rem 1rem 0px 0px;
    }
    
    .top{
        display:flex;
        padding-top: 1.5rem;
        width:  95%;
        justify-content: flex-end;
        height:5%;
    }
    .title{
        font-size: 2.5rem;
        color: $color-primary;
        // min-height: 5%;
        padding-top:2rem;
    }
    .subTitle{
        font-size: 1.5rem;
        // min-height:8%; 
        // min-height: 45px;
        color: $color-opt-black;
    }
    .checkboxes{
        margin-top:5%;
        width: 80%;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height:10%;
        min-height: 110px;
    
        .checkbox-container{
            display:flex;
            width: 90%;
            // margin-left: 15%;
            font-size: 1.2rem;
            justify-content: left;
            align-items: center;
            // margin-bottom: 2.5%;
            gap: 5%;
            min-height: 40px;
            margin-top:2.5%;
    
        }
    }
    .notes{
        margin-top:2%;
        min-height: 250px;
        height: 20%;
        width: 75%;
        .what-happened-note{
            columns: 8 !important;
        }
    }
    .send-copy{
        margin:unset;
        height:unset;
        padding: unset;
        min-height:unset;
    }

    .checkbox{
        min-width: 33px;
        min-height: 33px;
        margin: 0;
    }

    button.reschedule{
        min-height: 3.5rem;
        margin: 1rem 0;
        width: 80%;
    }

    .button-container.horizontal{
        width: 90%;
        height:15%;
        
        margin: auto auto 0 auto;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        
        box-sizing: border-box;


    }
}
@media only screen and (max-width: $mobile-width){
    .reschedule-container {
        .title {
            font-size: 1.8rem;
        }
        .notes {
            // height: 30%;
            // min-height: 200px;

            .what-happened-note {

            }
        }
        .button-container {
            height: unset;
            padding-top: 1rem;
        }
    }
}