
@import "src/stylesheets/abstracts/variables.scss";


.meeting-requested-container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display:flex;
    flex-direction: column;

    .slide-header,
    .slide-container,
    .button-container{
        width: 100% !important;
    }

    .slide-header{
        height: 15%;
        font-size: 4rem;
        text-align: center;
    }

    .slide-content{
        height: 75%;
        padding: 0 2rem;
    }

    .button-container {
        height: 10% !important;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 6%;
        }
    }
    
    
    .slide-content{

        .request-info-container{
            min-height: 35%;

            font-size: 1.5rem;
            text-align: center;

            display:flex;
            flex-direction: column;
            justify-content: space-around;
            align-items: center;

            color: white;
            background: $color-primary;
            border-radius: 5px;

            .date-and-time{
                font-weight: bold;
            }

            .meeting-with{
                display: flex;
                flex-direction: column;
            
                &-name{
                    font-weight: bold;
                }
            }
            .extra-info{
                width: 90%;
                font-size: 1rem;
            }

        }

        .other-details-container{
            padding-top:10%;
            min-height:20%;
            text-align: center;
            font-size: 1.5rem;
            color: $color-opt-black;
        }
        .location-details-container{
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .description{
                text-align: center;
                overflow: auto;
                height: 220px;
                .line{
                    text-align: center;
                }
                font-size: 1.2rem;

            }
        }
    }
}
@media only screen and (max-width: $mobile-width) {
    .meeting-requested-container {
        margin:unset;
        .slide-header {
            height: 12%;
            margin-bottom: 5%;
        }
        .slide-content {
            height: 73%;
            .other-details-container{
                font-size: 1.25rem;
                margin-bottom: 5%;
            }
            .location-details-container {
                font-size: 1.25rem;
                .description {
                    height: 140px;
                    font-size: 1rem;
                    
                }
            }
        }
    }
}

