
@import "src/stylesheets/abstracts/variables.scss";


    .toast-enter-from{
        opacity: 0;
        transform: translateY(60px);
    }
    .toast-enter-active{
        transition: all 0.3s ease;
    }
    .toast-leave-to{
        opacity: 0;
        transform: translateY(60px)
    }
    .toast-leave-active{
        transition: all 0.3s ease;
    }
    .toast{
        z-index: 25;
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        bottom: 20px;
        
        .toast-container{
            display:flex;
            flex-direction: column;
            justify-content: center;
            font-size: 1.25rem;
            text-align: center;
            padding: 20px;
            color: black;
            background: white;
            border-radius: 1rem;
            box-shadow: -1px 3px 6px 4px rgba(0,0,0,0.75);
            width: 600px;
            border: solid 1px $color-opt-grey;
            // min-width: 400px;
            margin: 0 auto;
            .top-section{
                display:flex;
                justify-content: center;

                .icon-header{
                    display:flex;
                    justify-content: center;
                    align-items: center;
            
                    .user-icon{
                        display:flex;
                        justify-content: center;

                        .profile{
                            background: $color-white;

                            &,.profile-pic,.profile-initials,svg{
                                height: 5rem;
                                width: 5rem;
                                border-radius: 50%;
                                color: $color-white;
                            }
                            .profile-pic{
                                object-fit: cover;
                            }
                            .profile-initials{
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 2.25rem;
                                background-color: rgba($color-primary, 0.67);
                            }
                        }
                    }
                }
                .message-container{
                    padding-top:2.5%;
                    padding-left:4%;
                    align-items: center;
                    justify-content: center;
                    display:flex;
                    flex-direction: column;
                    font-family: "Jost", sans-serif;
                    font-size: 18px;
                    .info{
                        line-height:15px;;
                    }
                    .name{
                        font-size: 32px;
                    }
                }
            }
            .bottom-section{
                padding-top:5%;
            }
        }
    
    }
