
@import "src/stylesheets/abstracts/variables.scss";


.profile-view {
    height: 100%;
    width: 100%;

    .profile-title {
        padding: 0rem 1.5rem;
        flex-grow: 1;
    }
    .profile-image {
        flex-grow: 2;

        .placeholder-image {
            aspect-ratio: 1 / 1;
            height: clamp(10rem, 30vh, 16rem);
            border-radius: 100%;
            object-fit: cover;
        }
    }

    .profile-btn {

        .upload {
            width: 300px;
            height: 60px;
        }
    }

    @media screen and (max-height: 800px) {
        .profile-image .placeholder-image {

        }
    }
}
