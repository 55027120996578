
@import "src/stylesheets/abstracts/variables.scss";



.payment-info-window{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(121, 121, 121, 0.15);

    .payment-info-popup{
        z-index: 16;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate( -50%, -50%);

        padding: 2rem;
        width: 45rem;
        border-radius: 3rem;
        background-color: white;

        .payment-info-loader{
            width: 100%;
            height: 100%;

            position: absolute;
            left: 0;
            top: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            z-index: 17;
            border-radius: 3rem;
            background-color: rgba(121, 121, 121, 0.151);
        }

        .popup-header{
            height: 10%;
            font-size: 2.5rem;
            text-align: center;
        }

        .payment-name{
            width: 80%;
            margin: 1rem auto;
        }

        #stripe-frame{
            // min-height: 250px;
        }

        .error{
            min-height: 50px;
            text-align: center;
            color: red;
            font-size: 1.5rem;
        }

        .popup-buttons{
            height: 10%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .finish{
                width: auto;
                padding: 1rem 2rem;
            }
            .cancel{
                padding-bottom: 0;
            }
        }
    }
    @media only screen and (max-width: 750px){
        &,.payment-info-loader,
        .payment-info-popup{
            overflow-y: auto;
            overflow-x: hidden
        }
    }
    @media only screen and  (max-height: 600px), (max-width: 816px){
        .payment-info-loader,
        .payment-info-popup{
            height: auto;
            width: 100vw;
            max-width: 100vw;
            height: 100vh;
            box-sizing: border-box;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-content: center;
        }
    }
    

    @media only screen and  (max-height: 800px){
        .payment-info-loader,
        .payment-info-popup{
            padding: 1.5rem;
            .popup-header{
                font-size: clamp(1.5rem, 8vw,2.5rem);
            }
        }
    }
}
@media only screen and (max-width: $mobile-width){
    .payment-info-window {
        padding-top: 5rem;
        position: absolute;
        top: 0rem;
        left: 0rem;

        .payment-info-popup{
            width: 100%;
            border-radius: unset;
            padding: 1rem;

            .popup-header {
                height: unset;
                font-size: 2rem;
            }
            .popup-buttons {
                height: unset;
                padding-bottom: 3rem;
            }
        }            
    }
}
