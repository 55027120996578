
@import "src/stylesheets/abstracts/variables.scss";


.referral-note{
    width: 100%;
    height: 100%;
    padding: 2rem;
    box-sizing: border-box;
    text-align: center;

    .title{
        font-size: 2.5rem;
        margin-top: 10%;
        height: 10%;
    }

    .description{
        margin-top: 10%;
        height: 10%;
    }

    .input-container{
        margin: 10% auto 0;
        height: 30%;
        width: 80%
    }

    .btn-container{
        margin-top: 50%;
        height: 10%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
}
