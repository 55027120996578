
@import "src/stylesheets/abstracts/variables.scss";



.modal-scrim {
    background: rgba(0, 0, 0, .25);
    position: fixed;
    left: 0; top: 0;
    width: 100%; height: 100%;
    z-index: 15;

    display: grid;
    place-items: center;
    .modal-card {
        background: white;
        padding: 2rem;
        border-radius: 0.5rem;
        box-shadow: 4px 2px 4px rgba($color-card-shadow, 0.25);

        .modal-card-buttons {
            display: flex;
            place-content: space-evenly;
        }
    }
}
