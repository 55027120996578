
@import "src/stylesheets/abstracts/variables.scss";


.balance {
    height: $card-height;
    width: 100%;
    box-sizing: border-box;
    position: relative;

    .profile-back-btn {
        color: $color-white;
        font-size: 18px;
        font-weight: thin;
        position: absolute;
        left: 0px;
    }

    .balance-header {
        width: 100%;
        height: 10%;
        background-color: $color-primary;
        box-sizing: border-box;
        flex-direction: row;

        .header-title {
            font-size: 2.5rem;
            color: $color-white;
        }
    }

    .current-balance {
        margin: auto;
        display: flex;
        height: 25%;
        min-height: 215px;
        width: 85%;
        box-sizing: border-box;
        border-bottom: 1px solid $color-opt-black;
        // margin-bottom: 1rem;

        .balance-total-column {
            display: flex;
            align-items: center;
            width: 60%;

            .balance-total {
                .currency {
                    font-size: 40px;
                    font-weight: normal;
                }
                .amount {
                    font-size: 80px; 
                    font-weight: normal;
                }

                &.negative {
                    color: $color-red-1;
                }
                &.zero {
                    .amount {
                        font-size: 80px;
                    }
                }
                &.ten {
                    .amount {
                        font-size: 5rem;
                    }
                }
                &.hundred {
                    .amount {
                        font-size: 4.2rem;
                    }
                }
                &.thousand {
                    .amount {
                        font-size: 3.6rem;
                    }
                }
                &.tenthousand {
                    .amount {
                       font-size: 3.2rem; 
                    }
                }
            }
        }

        .balance-description-column {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            width: 40%;

            .pending-container {
                .pending-header {
                    font-size: 18px;
                }

                .pending-total {
                    display: flex;
                    align-items: center;
                    gap: 5%;

                    .amount {
                        font-size: 22px;
                        color: $color-opt-grey;
                        font-weight: normal;
                    }
                }
            }

            .available-container {
                .available-header {
                    color: $color-opt-black;
                }

                .available-total {
                    color: $color-opt-black;
                    font-size: 18px;
                    font-weight: 700;

                    .amount {
                        font-size: 22px;
                        color: $color-opt-grey;
                        font-weight: normal;
                    }
                }
            }

            .withdraw-container {
                .withdrawBtn {
                    width: 100%;
                    color: #63ACE3;
                    border: #63ACE3 2px solid;

                    &:hover{
                        background: #63abe375;
                    }
                }
            }
        }
    }

    .balance-history {
        width: 90%;
        height: 47%;
        min-height: 200px;
        margin: auto;
        padding: 5% 0 5% 2.5%;
        box-sizing: border-box;
        font-size: 1rem;
        overflow-y: auto;

        &::-webkit-scrollbar {
            background: none;
            position: relative;

            &-track {
                background: rgb(156, 156, 156, 0.25);
                border-radius: .25em;
            }

            &-thumb {
                background: rgb(156, 156, 156, 0.75);
                width: 1em;
                border-radius: .25em;
            }
        }

        .transaction-header{
            display: inline-flex;
            align-items: center;
            gap: 2rem;

            button{
                height: 2rem;
            }
        }

        .transaction {
            .transaction-container{
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 1rem;

                .isNegative {
                    color: $color-primary;
                }

                &.pending {
                    font-size: 18px;
                    font-weight: normal;
                }

                &.failed {
                    color: $color-red-1;
                    font-size: 18px;
                    font-weight: normal;
                }

                &.canceled {
                    color: $color-grey-2;
                    font-size: 18px;
                    font-weight: normal;

                    .isNegative{
                        color: $color-grey-2
                    }
                }

                // &.posted {
                //     color: lightblue;
                //     font-size: 18px;
                //     font-weight: normal;
                // }

                .date {
                    width: 20%;
                    font-size: 18px;
                    font-weight: normal;
                }

                .from {
                    width: 30%;
                    font-size: 18px;
                    font-weight: 600;
                }

                .amount {
                    width: 25%;
                    text-align: right;
                    font-size: 18px;
                    font-weight: normal;
                }

                

                .status {
                    width: 20%;
                    padding-right: 2%;
                    display: flex;
                    justify-content: flex-end;
                    text-transform: capitalize;
                    font-size: 18px;
                    font-weight: normal;
                }
            }

        }
    }

    .bottom-balance {
        width: 100%;
        margin: auto;
        display: flex;
        flex-direction: column;

        .more-info {
            width: 100%;
            height: 30%;
            box-sizing: border-box;
            padding:2% 0;
            gap: 2%;
            display: flex;
            justify-content: center;

            .icon {
                width: 20px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 100%;
                background-color: #63ACE3;
                color: white;
            }

            .text {
                color: #63ACE3;
                margin-bottom: 2.5%;
            }
        }

        .balance-bottom {
            width: 100%;
            height: 70%;
            position: sticky;
            padding: unset;
            bottom: 0;
            flex-direction: row;
            justify-content: space-around;

            .sendInvite {
                height: unset;
            }
        }

        .return {
            padding: 0;
        }
    }

    .loading-container {
        width: 100%;
        height: 100%;
        z-index: 50;
        background-color: rgba(177, 177, 177, 0.5);
        position: absolute;
        top: 0;
        left: 0;
    }
}


@media only screen and (max-height: 1050px) {
    .bottom {
        padding-bottom: 5%;
    }

    .balance {
        .current-balance {
            min-height: 165px;
            .balance-total-column {
                .balance-total {
                    .currency {
                        // font-size: 2.5rem;
                    }

                    .amount {
                        // font-size: 3rem;
                    }
                }
            }
            .balance-description-column {
                .withdraw-container {
                    .withdrawBtn {
                        height: 40px;
                    }
                }
            }
        }

        .balance-history {
            height: 48%
        }
    }
}
@media only screen and (max-width: $mobile-width) {
    .balance {
        .current-balance {
            .balance-total-column {
                .balance-total {
                    .currency {
                        font-size: 40px;
                        font-weight: normal;
                    }
                    .amount {
                        font-size: 80px; 
                        font-weight: normal;
                    }

                    &.negative {
                        color: $color-red-1;
                    }
                    &.zero {
                        .amount {
                            font-size: 4rem;
                        }
                    }
                    &.ten {
                        .amount {
                            font-size: 3.7rem;
                        }
                    }
                    &.hundred {
                        .amount {
                            font-size: 3.1rem;
                        }
                    }
                    &.thousand {
                        .amount {
                            font-size: 2.5rem;
                        }
                    }
                    &.tenthousand {
                        .amount {
                        font-size: 2rem; 
                        }
                    }
                }
            }
        }
    }
}
