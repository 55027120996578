
@import "src/stylesheets/abstracts/variables.scss";


.meeting-link-popup{
    width: 100%;
    height: 100%;
    position: absolute;

    top:0;
    left:0;
    
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background: $color-white;
    

    button.close-btn{
        margin: 0.5rem 1rem 0px 0px;
    }

    .link-header{
        margin-top: 2.5rem;
        width: 100%;
        display: flex;
        align-items: center;
        .active-indicator{
            $svg-size: 60px;

            height: $svg-size;
            padding: 0 20px;

            svg, .off{
                height: $svg-size;
                width: $svg-size;
            }
            .off{
                font-weight: bold;
                font-size: 2rem;
            }
        }
        .invite-header{
            flex-grow: 1;

            .invite-name{
                font-size: 1.5rem;
                font-weight: bold;

                input{
                    font-size: 1.5rem;
                    width: 20rem;
                }
            }
        }
    }

    .popup-content{
        width: 100%;
        max-height: 80%;
        overflow-y: auto;

        .info-header{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin: 1rem 0;
            padding: 0.5rem 0;

            .dialogue{
                font-size: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;

                span{
                    color: $color-primary;
                    font-weight: 600;
                }

                .icon{
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0.25rem;

                    svg{
                        width: 30px;
                        height: 30px;
                        path{
                            fill: $color-primary;
                        }
                    }
                }
                
            }
        }

        .text-block{
            $block-width: 60%;
            margin: 1rem 0;
            .header,
            .description{
                width: $block-width;
                margin: auto;
            }

            .header{
                font-weight: 600;
                font-size: 1.5rem;
                margin-bottom: 0.5rem;
            }

            .description{
                font-size: 1rem;
                line-height: 1.5rem;

                textarea{
                    width: 100%;
                    resize: none;
                    padding: 1rem;
                    font-size: 1rem;
                }
            }
        }
    }

    .button-container{
        width: 70%;
        height: 20%;
        margin: auto auto 0 auto;

        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;

        box-sizing: border-box;

        button{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
        }
        .confirm-details:disabled{
            color: $color-grey-5;
        }

        .manage{
            position: relative;
            width: 100%;
        }

        .manage-popup{
            z-index: 1;
            position: absolute;
            bottom: 0;
            background-color: $color-white;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            .close-button{
                display: flex;
                justify-content: center;
                align-items: center;
                height: 3rem;

                cursor: pointer;
            }

            .qr-code{
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }
    }
    // .meeting-link-container{
    //     z-index: 10;
    //     width: 510px;
    //     height: 650px;
    //     background:white;
    //     border-radius: 10px;
    //     display:flex;
    //     flex-direction: column;
    //     align-items: center;
    //     font-size: 2rem;
    //     position: relative;

    //     &.withNote{
    //         height: 800px;
    //     }
    //     .exit-container{
    //         position: absolute;
    //         top:0;
    //         right: 2%;
    //         width: 5%;
    //         display:flex;
    //         justify-content: center;

    //         &:hover{
    //             cursor: pointer;
    //         }
    //     }

    //     .link-details{
    //         color: $color-grey-77;
    //         display: flex;
    //         flex-direction: column;
    //         justify-content: flex-end;
    //         // height:25%;
    //         height: 125px;
    //         font-size: 58px;
    //         font-weight: 300;
    //     }

    //     .note-container{
    //         // width: 80%;
    //         // height: 300px;
    //         width:80%;
    //         height:250px;

    //         .note{
    //              height: 85%;
    //             // height: 10rem !important;
    //         }
    //     }
    //     .link-payment{
    //         display:flex;
    //         flex-direction: column;
    //         // height:15%;
    //         height:75px;
    //         width:100%;
    //         justify-content: flex-end;
    //         .link-icon{
    //             width:25%;
    //             justify-content: center;
    //             display:flex;
    //             align-items: center;
    //             margin:auto;
    //         }
    //         .pay-icon{
    //             width: 15px;
    //             height: 25px;
    //             margin-right: 10px;
    //         }
    //         .amount{
    //             font-size: 30px;
    //             font-weight: 500;
    //             color: $color-grey-77;
    //         }

    //     }
    //     .link-container{
    //         display:flex;
    //         flex-direction: column;
    //         justify-content: center;
    //         align-items: center;
    //         width:100%;
    //         height: 40%;
    //         .url{
    //             margin:auto;
    //             text-align: right;
    //             width:87%;
    //             position: relative;
    //             .meeting-link{
    //                 width:98%;
    //                 height: 50px;
    //                 border-radius: 5px;
    //                 color: $color-blue-1;
    //                 border: solid 2px #d0d0d0;
    //                 font-size: 14px;
    //                 font-weight: 400;
    //                 text-align: center;
    //             }

    //             .copyIcon{
    //                 position: absolute;
    //                 top: 39%;
    //                 left:2.75%;
    //             }
    //         }
    //         .preview,
    //         .embededLink,
    //         .qrCode{
    //             margin:auto;
    //             width:87%;
    //             position: relative;
    //             .preview-link{
    //                 width:98%;
    //                 height: 50px;
    //                 border-radius: 5px;
    //                 color: $color-blue-1;
    //                 border: solid 2px #d0d0d0;
    //                 font-size: 14px;
    //                 font-weight: 400;
    //                 text-align: center;
    //             }
    //             .previewIcon{
    //                 position: absolute;
    //                 top: 37%;
    //                 left:21%;
    //             }
    //             .embededIcon{
    //                 position: absolute;
    //                 top: 30%;
    //                 left:22%;
    //             }
    //         }
    //         .qrCode{
    //             width: 87%;
    //         }
    //     }

    //     .btn-container{
    //         display:flex;
    //         flex-direction: row;
    //         justify-content: space-around;
    //         width:82%;
    //         padding-top: 1em;
    //         .copy-link{
    //             font-size: 1rem;
    //             position:relative;
    //             width: 180px;
    //             height:55px;

    //             .text{
    //                 width:80%;
    //                 padding-right: 1.5rem;
    //             }
    //             .copyImage{
    //                 width: auto;
    //                 height: auto;
    //                 margin-left: 25%;
    //                 position: absolute;
    //             }
    //         }
    //         .delete-link{
    //             font-size: 1rem;
    //             position: relative;
    //             width: 155px;
    //             height:55px;
    //             border: solid 1px $color-primary;
    //         }
            
    //     }
    //     .link-buttons{
    //         display:flex;
    //         justify-content: space-between;
    //     }
    // }
}
// @media only screen and (max-width: $mobile-width) {
//     .meeting-link-popup{
//         .meeting-link-container{

//             &.withNote{
//                 width: 95%;
//                 height: unset;
//             }

//             .link-details {
//                 font-size: 1.5rem;
//                 height: unset;
//                 margin-top: 1rem;
//             }
//             .link-payment {
//                 height: unset;
//                 .link-icon {
//                     justify-content: center;
//                 }
//                 .pay-icon {
//                     width: 15px;
//                     margin-right: 10px;
//                 }
//             }
//             .btn-container {
//                 padding-top: 1em;
//                 width: 100%;
//                 display: flex;
//                 justify-content: space-around;
//             }
//             .link-container {
//                 .url
//                 // ,.embededLink 
//                 {
//                     display: none;
//                     .copyIcon {
//                         display: none;
//                     }
//                     .meeting-link {
//                         text-align: center;
//                     }
//                 }
//                 .preview,
//                 .qrCode,
//                 .embededLink  {
//                     .previewIcon,
//                     .embededIcon {
//                         display: none;
//                     }
//                 }
//             }

            
//         }
//     }
// }




