input[type="radio"] {
    font-size: 1.5em;
	appearance: none;
	width: 1em;
	height: 1em;
	border: 2px solid lightgray;
	border-radius: 50%;
	background-clip: content-box;
	padding: 0.14em;
    margin-top: -2px;
}

input[type="radio"]:checked {
    border: 2px solid $color-primary;
	background-color: $color-primary;
}

input[type="radio"]:disabled {
	border: 2px solid rgb(235, 235, 235);
}