
@import "src/stylesheets/abstracts/variables.scss";


$text-padding: 1em;
$color-red: #FD2C34FF;
.phone-input-container {
    width: 100%;
    border-radius: 0.25rem;
    display: inline-flex;
    background: white;
    color: inherit;
    border: 1px solid #D5D5D5FF;
    transition: border .25s ease;
    box-sizing: border-box;

    .separator {
        width: 1px;
        border-left: 1px solid #D5D5D5FF;
        margin: 2px 0;
    }
    select{
        margin: $text-padding;
    }
    input{
        margin: $text-padding 0;
        width: 100%;
    }
    select, input {
        border: none;
        background: none;
        outline: none;
        color: inherit;
        font-size: inherit;
    }
    .input-container{
        flex: 1;
        margin: 0 $text-padding;
    }
    
    .input-container{
        position: relative;

        .input-label {
            z-index: 2;

            pointer-events: none;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate($text-padding, $text-padding);
            transition: transform .25s;
        }

        .input:disabled{
            opacity: .5;
        }

        .input:disabled + .input-label{
            opacity: .7;
        }

        .phone-input:focus+.input-label
        ,.phone-input:not(:placeholder-shown)+.input-label
        {
            transform: translate($text-padding, -$text-padding) scale(.9); //Posistion of the label when selected
            padding-inline: 5px;
            background-color: #fff;
        }
    }

    &:not(.valid),
    &:focus-within{
        border: 1px solid $color-red;

        .separator {
            border-left: 1px solid $color-red;
        }

        .input-label {
            color: $color-red;
        }
    }
}
